import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import Popover from "@mui/material/Popover"
import Typography from "@mui/material/Typography"
import { useState } from "react"
import { useSelector } from "react-redux"
import { Link, NavLink } from "react-router-dom"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import { selectUser } from "app/store/user/userSlice"

/**
 * The user menu.
 */
function UserMenu() {
  const user = useSelector(selectUser)

  const [userMenu, setUserMenu] = useState<HTMLElement | null>(null)

  const userMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenu(event.currentTarget)
  }

  const userMenuClose = () => {
    setUserMenu(null)
  }

  return (
    <>
      <Button
        className="min-h-40 min-w-40 p-0 md:px-16 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="mx-4 hidden flex-col items-end md:flex">
          <Typography component="span" className="flex font-semibold">
            {user?.firstName + " " + user?.lastName}
          </Typography>
          <Typography
            className="text-11 font-medium capitalize"
            color="text.secondary"
          >
            {user?.authorities[0].authority}
          </Typography>
        </div>

        {user?.photos.length > 0 ? (
          <Avatar
            className="md:mx-4"
            alt="user photo"
            src={
              "https://cpp-photo-bucket.s3.eu-north-1.amazonaws.com/" +
              user?.photos[0]?.s3Url
            }
          />
        ) : (
          <Avatar className="md:mx-4">{user?.firstName.charAt(0)}</Avatar>
        )}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        classes={{
          paper: "py-8"
        }}
      >
        {!user?.authorities[0].authority ||
        user?.authorities[0].authority.length === 0 ? (
          <>
            <MenuItem component={Link} to="/sign-in" role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:lock-closed</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </MenuItem>
            <MenuItem component={Link} to="/sign-up" role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:user-add </FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign up" />
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              component={NavLink}
              to="/profile"
              onClick={() => {
                userMenuClose()
              }}
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:user</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Mon profil" />
            </MenuItem>

            <MenuItem
              component={NavLink}
              to="/resetpassword"
              onClick={() => {
                userMenuClose()
              }}
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:lock-closed</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Réinitialiser le mot de passe" />
            </MenuItem>

            <MenuItem
              component={NavLink}
              to="/sign-out"
              onClick={() => {
                userMenuClose()
              }}
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Se déconnecter" />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  )
}

export default UserMenu
