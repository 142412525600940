import React, { useState } from "react";
import FusePageSimple from "@fuse/core/FusePageSimple";
import ProjectDashboardAppHeader from "../project/ProjectDashboardAppHeader";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

// Tabs
import Home from "./tabs/homeTab/Home";
import Groups from "./tabs/Groups";
import { useSnackbar } from "notistack";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
  },
}));

const AdminDashboard = () => {
  const [tabValue, setTabValue] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  function handleChangeTab(event: React.SyntheticEvent, value: number) {
    setTabValue(value);
  }

  // enqueueSnackbar("Inscription annulée avec succès", {
  //   variant: "success",
  //   anchorOrigin: {
  //     vertical: "top",
  //     horizontal: "right",
  //   },
  // });
  return (
    <Root
      header={<ProjectDashboardAppHeader />}
      content={
        <div className="w-full p-12 pt-16 sm:pt-24 lg:ltr:pr-0 lg:rtl:pl-0">
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons={false}
            className="w-full px-24 -mx-4 min-h-40"
            classes={{
              indicator: "flex justify-center bg-transparent w-full h-full",
            }}
            TabIndicatorProps={{
              children: (
                <Box
                  sx={{ bgcolor: "text.disabled" }}
                  className="w-full h-full rounded-full opacity-20"
                />
              ),
            }}
          >
            <Tab
              className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
              disableRipple
              label="Accueil"
            />
          </Tabs>
          {tabValue === 0 && <Home />}
          {tabValue === 1 && <Groups />}
        </div>
      }
    />
  );
};

export default AdminDashboard;
