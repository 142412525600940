import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Tooltip from "@mui/material/Tooltip"
import { MouseEvent, useState } from "react"
import Box from "@mui/material/Box"
import TableHead from "@mui/material/TableHead"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import { lighten } from "@mui/material/styles"
import { useAppDispatch } from "app/store"

/**
 * The table head row type.
 */
type rowType = {
  id: string
  align: "left" | "center" | "right"
  disablePadding: boolean
  label: string
  sort: boolean
}

/**
 * The table head rows data.
 */
const rows: rowType[] = [
  {
    id: "",
    align: "left",
    disablePadding: true,
    label: "",
    sort: false
  },
  {
    id: "teacherName",
    align: "left",
    disablePadding: false,
    label: "Professeur",
    sort: true
  },
  {
    id: "studentName",
    align: "left",
    disablePadding: false,
    label: "étudiant",
    sort: true
  },
  {
    id: "courseTitle",
    align: "left",
    disablePadding: false,
    label: "Cours",
    sort: true
  },
  {
    id: "subscriptionType",
    align: "left",
    disablePadding: false,
    label: "Abonnement",
    sort: true
  },
  {
    id: "groupName",
    align: "left",
    disablePadding: false,
    label: "Nom du groupe",
    sort: true
  },

  {
    id: "paymentDate",
    align: "left",
    disablePadding: false,
    label: "Date d'incription",
    sort: true
  },
  {
    id: "price",
    align: "left",
    disablePadding: false,
    label: "Prix",
    sort: true
  },
  {
    id: "paymentMethod",
    align: "left",
    disablePadding: false,
    label: "Méthode de paiement",
    sort: true
  },
  {
    id: "enrollmentStatusText",
    align: "left",
    disablePadding: false,
    label: "Inscription",
    sort: true
  },
  {
    id: "paymentStatusText",
    align: "left",
    disablePadding: false,
    label: "Paiement",
    sort: true
  },

  {
    id: "action",
    align: "left",
    disablePadding: false,
    label: "",
    sort: false
  }
]

type PaymentsTableHeadPropsType = {
  selectedProductIds: string[]
  onRequestSort: (event: MouseEvent<HTMLSpanElement>, property: string) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  tableOrder: {
    direction: "asc" | "desc"
    id: string
  }
  rowCount: number
  onMenuItemClick: () => void
  handleDeleteItem: () => void
}

/**
 * The products table head component.
 */
function PaymentsAdminTableHead(props: PaymentsTableHeadPropsType) {
  const {
    selectedProductIds,
    tableOrder,
    onSelectAllClick,
    onRequestSort,
    rowCount,
    onMenuItemClick,
    handleDeleteItem
  } = props

  const numSelected = selectedProductIds.length

  const [selectedProductsMenu, setSelectedProductsMenu] =
    useState<HTMLButtonElement | null>(null)

  const dispatch = useAppDispatch()

  const createSortHandler = (
    event: MouseEvent<HTMLSpanElement>,
    property: string
  ) => {
    onRequestSort(event, property)
  }

  function openSelectedProductsMenu(event: MouseEvent<HTMLButtonElement>) {
    setSelectedProductsMenu(event.currentTarget)
  }

  function closeSelectedProductsMenu() {
    setSelectedProductsMenu(null)
  }

  return (
    <TableHead>
      <TableRow className="h-48 sm:h-64">
        <TableCell
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? lighten(theme.palette.background.default, 0.4)
                : lighten(theme.palette.background.default, 0.02)
          }}
          padding="none"
          className="w-40 md:w-64 text-center z-99"
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount !== 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
          {numSelected > 0 && (
            <Box
              className="flex items-center justify-center absolute w-64 top-0 ltr:left-0 rtl:right-0 mx-56 h-64 z-10 border-b-1"
              sx={{
                background: (theme) => theme.palette.background.default
              }}
            >
              <IconButton
                aria-haspopup="true"
                onClick={openSelectedProductsMenu}
                size="large"
              >
                <FuseSvgIcon>heroicons-outline:dots-horizontal</FuseSvgIcon>
              </IconButton>
              <Menu
                id="selectedProductsMenu"
                anchorEl={selectedProductsMenu}
                open={Boolean(selectedProductsMenu)}
                onClose={closeSelectedProductsMenu}
              >
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      closeSelectedProductsMenu()
                      handleDeleteItem()
                    }}
                  >
                    <ListItemIcon className="min-w-40">
                      <FuseSvgIcon>heroicons-outline:trash</FuseSvgIcon>
                    </ListItemIcon>
                    <ListItemText primary="Remove" />
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          )}
        </TableCell>
        {rows.map((row) => {
          return (
            <TableCell
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? lighten(theme.palette.background.default, 0.4)
                    : lighten(theme.palette.background.default, 0.02)
              }}
              className="p-4 md:p-16"
              key={row.id}
              align={row.align}
              padding={row.disablePadding ? "none" : "normal"}
              sortDirection={
                tableOrder.id === row.id ? tableOrder.direction : false
              }
            >
              {row.sort && (
                <Tooltip
                  title="Sort"
                  placement={
                    row.align === "right" ? "bottom-end" : "bottom-start"
                  }
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={tableOrder.id === row.id}
                    direction={tableOrder.direction}
                    onClick={(ev: MouseEvent<HTMLSpanElement>) =>
                      createSortHandler(ev, row.id)
                    }
                    className="font-semibold"
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              )}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export default PaymentsAdminTableHead
