import React, { useEffect, useState } from "react";
import ProfileEdit from "./ProfileEdit";
import ProfileView from "./ProfileView";
import { useAppSelector } from "app/store";
import { selectUser } from "app/store/user/userSlice";
import { AdminProfileTypes } from "./types";
import { ApiServices } from "src/services/ApiServices";

const Profile = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { id, token } = useAppSelector(selectUser);

  const [admin, setAdmin] = useState<AdminProfileTypes | null>();

  const loadData = () => {
    setAdmin(null);
    ApiServices.getAdminDetailsById(token, id)
      .then((res) => {
        setAdmin(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadData();
  }, []);
  return (
    <>
      {!isEdit ? (
        <ProfileView handleEdit={() => setIsEdit(!isEdit)} userData={admin} />
      ) : (
        <ProfileEdit
          handleEdit={() => setIsEdit(!isEdit)}
          userData={admin}
          loadData={loadData}
        />
      )}
    </>
  );
};

export default Profile;
