import React from "react";
import _ from "@lodash";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { TeacherProfileTypes } from "./types";
import ChangeProfile from "./ChangeProfile";

function BirtdayIcon() {
  return <FuseSvgIcon size={20}>heroicons-solid:cake</FuseSvgIcon>;
}

// Types
interface ProfileEditTypes {
  handleEdit: () => void;
  userData: TeacherProfileTypes;
  loadData: () => void;
}

/**
 * The contact form.
 */
const ProfileEdit: React.FC<ProfileEditTypes> = ({
  handleEdit,
  userData,
  loadData,
}) => {
  return (
    <ChangeProfile
      handleEdit={handleEdit}
      userData={userData}
      loadData={loadData}
    />
  );
};

export default ProfileEdit;
