import i18next from "i18next"
import { FuseNavigationType } from "@fuse/core/FuseNavigation/types/FuseNavigationType"
import ar from "./navigation-i18n/ar"
import en from "./navigation-i18n/en"
import tr from "./navigation-i18n/tr"

i18next.addResourceBundle("en", "navigation", en)
i18next.addResourceBundle("tr", "navigation", tr)
i18next.addResourceBundle("ar", "navigation", ar)

/**
 * The navigationConfig object is an array of navigation items for the Fuse application.
 */
const navigationConfig: FuseNavigationType = [
  {
    id: "dashboards",
    title: "Tableau de bord",
    type: "item",
    icon: "heroicons-outline:home",
    url: "/"
  },

  {
    id: "courses",
    title: "Mes cours",
    type: "item",
    url: "/courses",
    icon: "heroicons-outline:presentation-chart-bar",
    auth: "TEACHER"
  },
  {
    id: "student",
    title: "Mes étudiants",
    type: "item",
    url: "/students",
    icon: "heroicons-outline:user",
    auth: "TEACHER"
  },

  {
    id: "payments",
    title: "Mes paiement",
    type: "item",
    url: "/payment-status",
    icon: "heroicons-outline:currency-dollar",
    auth: "TEACHER"
  },

  // Subjects
  {
    id: "subjects",
    title: "Matières",
    type: "item",
    url: "/subjects",
    icon: "heroicons-outline:book-open",
    auth: "ADMIN"
  },
  {
    id: "study_levels",
    title: "Niveaux d'études",
    type: "item",
    url: "/study_levels",
    icon: "heroicons-outline:academic-cap",
    auth: "ADMIN"
  },
  {
    id: "specialities",
    title: "Filières",
    type: "item",
    url: "/specialities",
    icon: "heroicons-outline:briefcase",
    auth: "ADMIN"
  },
  {
    id: "users",
    title: "Utilisateurs",
    type: "item",
    url: "/users",
    icon: "heroicons-outline:user-group",
    auth: "ADMIN"
  },
  // Student
  {
    id: "lessons",
    title: "Mes cours",
    type: "item",
    url: "/lessons",
    icon: "heroicons-outline:book-open",
    auth: "STUDENT"
  },

  {
    id: "paymentsAdmin",
    title: "Historique des Paiements",
    type: "item",
    url: "/payment-admin",
    icon: "heroicons-outline:currency-dollar",
    auth: "ADMIN"
  },
  {
    id: "paymentsAdminTeacher",
    title: "Historique des professeurs",
    type: "item",
    url: "/payment-admin-teacher",
    icon: "heroicons-outline:currency-dollar",
    auth: "ADMIN"
  },
  {
    id: "paymentsRenew",
    title: "Renouvellement",
    type: "item",
    url: "/payment-renew",
    icon: "heroicons-outline:currency-dollar",
    auth: "STUDENT"
  },

  {
    id: "refunds",
    title: "Remboursements",
    type: "item",
    url: "/refunds",
    icon: "heroicons-outline:currency-dollar",
    auth: "ADMIN"
  },
  {
    id: "support",
    title: "Support",
    type: "item",
    url: "/support",
    icon: "heroicons-outline:support",
    auth: ["TEACHER", "STUDENT"]
  },
  {
    id: "faq",
    title: "Centre d'aide",
    type: "item",
    url: "/faq",
    icon: "heroicons-outline:question-mark-circle",
    auth: ["TEACHER", "STUDENT"]
  }
]

export default navigationConfig
