import Button from "@mui/material/Button"
import Input from "@mui/material/Input"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { motion } from "framer-motion"
import { useAppDispatch, useAppSelector } from "app/store"
import { Link } from "react-router-dom"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import { ChangeEvent, useEffect, useState } from "react"

interface courseHeaderTypes {
  searchInputHandle: (text: string) => void
}
/**
 * The products header.
 */
function CourseHeader(props: courseHeaderTypes) {
  const { searchInputHandle } = props
  const [searchText, setSearchText] = useState<string>("")

  useEffect(() => {
    searchInputHandle(searchText)
  }, [searchText])

  return (
    <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32">
      <motion.span
        initial={{ x: -20 }}
        animate={{ x: 0, transition: { delay: 0.2 } }}
      >
        <Typography className="text-24 md:text-32 font-extrabold tracking-tight">
          Liste des cours
        </Typography>
      </motion.span>
      <div className="flex flex-col w-full sm:w-auto sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center justify-end space-x-8">
        <Paper
          component={motion.div}
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
          className="flex items-center w-full sm:max-w-256 space-x-8 px-16 rounded-full border-1 shadow-0"
        >
          <FuseSvgIcon color="disabled">heroicons-solid:search</FuseSvgIcon>

          <Input
            placeholder="Rechercher un cours"
            className="flex flex-1"
            disableUnderline
            fullWidth
            value={searchText}
            inputProps={{
              "aria-label": "Search"
            }}
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setSearchText(ev.target.value)
            }
          />
        </Paper>
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
        >
          <Button
            component={Link}
            to="/course/new/course"
            variant="contained"
            color="secondary"
            startIcon={<FuseSvgIcon>heroicons-outline:plus</FuseSvgIcon>}
          >
            Ajouter un cours
          </Button>
        </motion.div>
      </div>
    </div>
  )
}

export default CourseHeader
