import FuseLoading from "@fuse/core/FuseLoading";
import FusePageCarded from "@fuse/core/FusePageCarded";
import { useDeepCompareEffect } from "@fuse/hooks";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import React, { SyntheticEvent, useState } from "react";
import { Link, useParams } from "react-router-dom";
import _ from "@lodash";
import { FormProvider, useForm } from "react-hook-form";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import ProductHeader from "./FormHeader";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { ApiServices } from "src/services/ApiServices";

type FormValues = {
  name: string;
};

/**
 * The product page.
 */
function Subject() {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));

  const routeParams = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [noProduct, setNoProduct] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm<FormValues>({
    mode: "onChange",
  });

  const { control, formState, register, setValue } = methods;

  useDeepCompareEffect(() => {
    function updateProductState() {
      const { id, update } = routeParams;

      if (id !== "new") {
        setLoading(true);
        ApiServices.getSubjectById(id)
          .then((res) => {
            setValue("name", res.data.data.name);
            setLoading(false);
          })
          .catch((err) => {
            setNoProduct(true);
            setLoading(false);
          });
      }
    }

    updateProductState();
  }, [routeParams]);

  /**
   * Tab Change
   */
  function handleTabChange(event: SyntheticEvent, value: number) {
    setTabValue(value);
  }
  /**
   * Show Message if the requested products is not exists
   */
  if (noProduct) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-col flex-1 items-center justify-center h-full"
      >
        <Typography color="text.secondary" variant="h5">
          There is no such product!
        </Typography>
        <Button
          className="mt-24"
          component={Link}
          variant="outlined"
          to="/apps/e-commerce/products"
          color="inherit"
        >
          Go to Products Page
        </Button>
      </motion.div>
    );
  }

  /**
   * Wait while product data is loading and form is setted
   */
  if (loading) {
    return <FuseLoading />;
  }

  return (
    <>
      <FormProvider {...methods}>
        <FusePageCarded
          header={<ProductHeader />}
          content={
            <>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                classes={{ root: "w-full h-64 border-b-1" }}
              >
                <Tab className="h-64" label="Basic Info" />
              </Tabs>
              <div className="p-16 sm:p-24 max-w-3xl">
                <div className={tabValue !== 0 ? "hidden" : ""}>
                  <div>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mt-8 mb-16"
                          required
                          label="Name"
                          autoFocus
                          id="name"
                          variant="outlined"
                          fullWidth
                          {...register("name", {
                            required: {
                              value: true,
                              message: "name is required",
                            },
                          })}
                          error={!!formState.errors.name}
                          helperText={formState.errors?.name?.message as string}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </>
          }
          scroll={isMobile ? "normal" : "content"}
        />
      </FormProvider>
    </>
  );
}

export default Subject;
