import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useFormContext } from "react-hook-form";
import { useAppSelector } from "app/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "@lodash";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { ApiServices } from "src/services/ApiServices";
import { selectUser } from "app/store/user/userSlice";
import { UserTypes } from "../types/users";

/**
 * The product header.
 */
function UsersFormHeader() {
  const { token } = useAppSelector(selectUser);
  const param = useParams().id;
  const methods = useFormContext();
  const { formState, watch, getValues } = methods;
  const { isValid, dirtyFields } = formState;

  const theme = useTheme();
  const navigate = useNavigate();

  const { firstName, photos } = watch() as UserTypes;

  function handleSaveProduct() {
    const {
      firstName,
      lastName,
      email,
      phone,
      gender,
      aboutMe,
      birthDate,
      city,
      address,
      userImage,
    } = getValues() as UserTypes;

    const data = {
      firstName,
      lastName,
      email,
      phone,
      gender,
      aboutMe,
      birthDate,
      userImage,
      city,
      address,
    };

    if (param === "new") {
      // ApiServices.storeSubject(token, data)
      //   .then((res) => navigate(-1))
      //   .catch((err) => console.log(err));
    } else {
      ApiServices.updateUserById(token, Number(param), data)
        .then((res) => {
          console.log(res);
          // navigate(-1);
        })
        .catch((err) => console.log(err));
    }
  }

  function handleRemoveProduct() {
    if (param !== "new") {
      ApiServices.removeUserById(token, param)
        .then((res) => {
          console.log(res);
          navigate(-1);
        })
        .catch((err) => console.log(err));
    } else {
      navigate(-1);
    }
  }

  return (
    <div className="flex flex-col sm:flex-row flex-1 w-full items-center justify-between space-y-8 sm:space-y-0 py-32 px-24 md:px-32">
      <div className="flex flex-col items-center sm:items-start space-y-8 sm:space-y-0 w-full sm:max-w-full min-w-0">
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
        >
          <Typography
            className="flex items-center sm:mb-12"
            component={Link}
            role="button"
            to="/users"
            color="inherit"
          >
            <FuseSvgIcon size={20}>
              {theme.direction === "ltr"
                ? "heroicons-outline:arrow-sm-left"
                : "heroicons-outline:arrow-sm-right"}
            </FuseSvgIcon>
            <span className="flex mx-4 font-medium">User</span>
          </Typography>
        </motion.div>

        <div className="flex items-center max-w-full">
          <motion.div
            className="hidden sm:flex"
            initial={{ scale: 0 }}
            animate={{ scale: 1, transition: { delay: 0.3 } }}
          >
            {photos && photos.length > 0 ? (
              <img
                className="w-32 sm:w-48 rounded"
                src={`${photos}`}
                alt={firstName}
              />
            ) : (
              <img
                className="w-32 sm:w-48 rounded"
                src="assets/images/apps/ecommerce/product-image-placeholder.png"
                alt={firstName}
              />
            )}
          </motion.div>
          <motion.div
            className="flex flex-col items-center sm:items-start min-w-0 mx-8 sm:mx-16"
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.3 } }}
          >
            <Typography className="text-16 sm:text-20 truncate font-semibold">
              {firstName || "New User"}
            </Typography>
            <Typography variant="caption" className="font-medium">
              User Detail
            </Typography>
          </motion.div>
        </div>
      </div>
      <motion.div
        className="flex"
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
      >
        {/* <Button
          className="whitespace-nowrap mx-4"
          variant="contained"
          color="secondary"
          onClick={handleRemoveProduct}
          startIcon={
            <FuseSvgIcon className="hidden sm:flex">
              heroicons-outline:trash
            </FuseSvgIcon>
          }
        >
          Remove
        </Button> */}
        <Button
          className="whitespace-nowrap mx-4"
          variant="contained"
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={handleSaveProduct}
        >
          Save
        </Button>
      </motion.div>
    </div>
  );
}

export default UsersFormHeader;
