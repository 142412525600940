import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { lighten } from "@mui/material/styles";
import CourseInfo from "../CourseInfo";
import GroupType from "../types/GroupType";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { useAppSelector } from "app/store";
import { selectUser } from "app/store/user/userSlice";
import { ApiServices } from "src/services/ApiServices";
import { useSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type CourseCardProps = {
  course: GroupType;
};

/**
 * The CourseCard component.
 */
function LessonCard(props: CourseCardProps) {
  const { course } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { token } = useAppSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
    handleMenuClose();
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancelSubscription = () => {
    ApiServices.handleCancelSubscribtionRequest(token, course.enrollmentId)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          enqueueSnackbar("Inscription annulée avec succès", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else {
          enqueueSnackbar(
            res.data.error || "Erreur lors de l'annulation de l'inscription",
            {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        }
      })
      .catch((err) => {
        enqueueSnackbar("Une erreur s'est produite lors de l'appel API", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        console.error("API call failed:", err);
      })
      .finally(() => {
        handleCloseConfirmDialog();
      });
  };

  return (
    <Card className="flex flex-col h-h-auto shadow">
      <CardContent className="flex flex-col flex-auto p-24">
        <Box className="flex justify-between items-start">
          <CourseInfo course={course} />
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            size="large"
          >
            <FuseSvgIcon>heroicons-outline:dots-horizontal</FuseSvgIcon>
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose}>Signaler</MenuItem>
            <MenuItem onClick={handleOpenConfirmDialog}>
              Annuler l'inscription
            </MenuItem>
          </Menu>
        </Box>
      </CardContent>
      {/* <CourseProgress course={course} /> */}
      <CardActions
        className="items-center justify-end py-16 px-24"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.03),
        }}
      >
        <Button
          to={`https://meet.google.com/bpr-ntxz-xug`}
          component={Link}
          className="px-16 min-w-128"
          color={
            course.enrollmentStatus === "CANCELLED" ? "inherit" : "secondary"
          }
          variant="contained"
          disabled={course.enrollmentStatus === "CANCELLED"}
          endIcon={
            <FuseSvgIcon size={20}>heroicons-solid:video-camera</FuseSvgIcon>
          }
        >
          Rejoindre la réunion
        </Button>
      </CardActions>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation de l'annulation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes-vous sûr de vouloir annuler votre inscription à ce cours ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={handleCancelSubscription} color="primary" autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default LessonCard;
