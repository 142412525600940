import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import clsx from "clsx"
import CourseType from "./GroupType"
import TagChip from "app/shared-components/TagChip"

type CourseInfoProps = {
  course: CourseType
  className?: string
}

/**
 * The CourseInfo component.
 */
function CourseInfo(props: CourseInfoProps) {
  const { course, className } = props

  if (!course) {
    return null
  }

  function formatDate(dateArray) {
    if (!dateArray || dateArray.length !== 3) return ""

    const [year, month, day] = dateArray

    // Note: JavaScript months are 0-indexed, so we need to subtract 1 from the month
    const date = new Date(year, month - 1, day)

    // Format the date
    return date.toLocaleDateString([], {
      year: "numeric",
      month: "short",
      day: "numeric"
    })
  }

  function formatTime(timeArray) {
    if (!timeArray || timeArray.length !== 2) return ""

    const [hour, minute] = timeArray

    const date = new Date()
    date.setHours(hour)
    date.setMinutes(minute)

    return date.toLocaleTimeString([], {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit"
    })
  }

  return (
    <div className={clsx("w-full", className)}>
      <div className="flex items-center justify-between mb-16">
        <TagChip
          category={{
            name: course.subscription.type,
            type: course.subscription.type
          }}
        />

        <FuseSvgIcon className="text-green-600" size={20}>
          heroicons-solid:badge-check
        </FuseSvgIcon>
        {/* {course.progress.completed > 0 && (
        <FuseSvgIcon className="text-green-600" size={20}>
          heroicons-solid:badge-check
        </FuseSvgIcon>
        )} */}
      </div>

      <Typography className="text-16 font-medium">{course.name}</Typography>

      <Typography className="text-13 mt-2 line-clamp-2" color="text.secondary">
        {course.courseName}
      </Typography>

      <Divider className="w-48 my-24 border-1" light />

      <Typography
        className="flex items-center space-x-6 text-13"
        color="text.secondary"
      >
        <FuseSvgIcon color="disabled" size={20}>
          heroicons-solid:clock
        </FuseSvgIcon>
        <span className="whitespace-nowrap leading-none">{`${course.slots[0].recurrenceDays}`}</span>
      </Typography>

      <Typography
        className="flex items-center space-x-6 text-13 mt-8"
        color="text.secondary"
      >
        <FuseSvgIcon color="disabled" size={20}>
          heroicons-solid:academic-cap
        </FuseSvgIcon>
        <span className="whitespace-nowrap leading-none">{`${formatTime(
          course.slots[0].startTime
        )} - ${formatTime(course.slots[0].endTime)}`}</span>
      </Typography>

      <Typography
        className="flex items-center space-x-6 text-13 mt-8"
        color="text.secondary"
      >
        <FuseSvgIcon color="disabled" size={20}>
          heroicons-solid:academic-cap
        </FuseSvgIcon>
        <span className="whitespace-nowrap leading-none">{`${formatDate(
          course.slots[0].endDate
        )}`}</span>
      </Typography>
    </div>
  )
}

export default CourseInfo
