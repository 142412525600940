import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"
import CountCard from "app/shared-components/CountCard"
import { ApiServices } from "src/services/ApiServices"
import { TeacherStatistics as TeacherStatisticsType } from "../../types"
import MonthlyReveneueChart from "app/shared-components/MonthlyRevenueChart"
import { Skeleton } from "@mui/material"
import { selectUser } from "app/store/user/userSlice"
import { useAppSelector } from "app/store"

const Home: React.FC = () => {
  const { token, id } = useAppSelector(selectUser)

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1
      }
    }
  }

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  }

  const [teacherStatistics, setTeacherStatistics] =
    useState<TeacherStatisticsType | null>(null)

  useEffect(() => {
    ApiServices.getTeacherStatistics(token, Number(id))
      .then((res) => setTeacherStatistics(res.data.data))
      .catch((err) => console.log(err))
  }, [token, id])

  return (
    <>
      <motion.div
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-24 w-full min-w-0 p-24"
        variants={container}
        initial="hidden"
        animate="show"
      >
        {/* Cards */}
        {!teacherStatistics ? (
          <Skeleton variant="rectangular" className="rounded-8 h-[220px]" />
        ) : (
          <motion.div variants={item}>
            <CountCard
              title="Total des revenus"
              mainText={
                teacherStatistics?.totalRevenueGenerated
                  ? `${teacherStatistics.totalRevenueGenerated} Dhs`
                  : "0 Dhs"
              }
              subText="Profit"
              footerText="Dernière mise à jour"
              footerCount="à l'instant"
              textColor="text-blue-600"
              darkTextColor="dark:text-blue-500"
            />
          </motion.div>
        )}
        {/* Card */}
        {!teacherStatistics ? (
          <Skeleton variant="rectangular" className="rounded-8 h-[220px]" />
        ) : (
          <motion.div variants={item}>
            <CountCard
              title="Nombre d'étudiants"
              mainText={
                teacherStatistics?.totalStudentsEnrolled
                  ? String(teacherStatistics.totalStudentsEnrolled)
                  : "0"
              }
              subText="Étudiants"
              footerCount="à l'instant"
              footerText="Dernière mise à jour"
              darkTextColor="dark:text-red-200"
              textColor="text-red-500"
            />
          </motion.div>
        )}
        {/* Card */}
        {!teacherStatistics ? (
          <Skeleton variant="rectangular" className="rounded-8 h-[220px]" />
        ) : (
          <motion.div variants={item}>
            <CountCard
              title="Cours enseignés"
              mainText={
                teacherStatistics?.totalCoursesTaught
                  ? String(teacherStatistics.totalCoursesTaught)
                  : "0"
              }
              subText="Cours"
              footerCount="à l'instant"
              footerText="Dernière mise à jour"
              darkTextColor="dark:text-amber-600"
              textColor="text-amber-600"
            />
          </motion.div>
        )}
        {/* Card 
        {!teacherStatistics ? (
          <Skeleton variant="rectangular" className="rounded-8 h-[220px]" />
        ) : (
          <motion.div variants={item}>
            <CountCard
              title="Test"
              mainText="200"
              subText="Test"
              footerCount="à l'instant"
              footerText="Dernière mise à jour"
              darkTextColor="dark:text-green-500"
              textColor="text-green-500"
            />
          </motion.div>
        )}
          */}
      </motion.div>
      <div className="pl-24 pr-24">
        {!teacherStatistics ? (
          <Skeleton
            variant="rectangular"
            className="rounded-8 w-full h-[300px]"
          />
        ) : (
          <MonthlyReveneueChart
            title="Statistiques des revenus par mois"
            title1="Total revenu par mois"
            title2="Détail des revenus par mois"
            revenueData={{
              monthlyRevenue: teacherStatistics.monthlyRevenue,
              monthlyRevenueSilver: teacherStatistics.monthlyRevenueSilver,
              monthlyRevenueGold: teacherStatistics.monthlyRevenueGold,
              monthlyRevenuePlatinum: teacherStatistics.monthlyRevenuePlatinum,
              activeStudentsByMonthSilver:
                teacherStatistics.activeStudentsByMonthSilver,
              activeStudentsByMonthGold:
                teacherStatistics.activeStudentsByMonthGold,
              activeStudentsByMonthPlatinum:
                teacherStatistics.activeStudentsByMonthPlatinum,
              unsubscribedStudentsByMonthSilver:
                teacherStatistics.unsubscribedStudentsByMonthSilver,
              unsubscribedStudentsByMonthGold:
                teacherStatistics.unsubscribedStudentsByMonthGold,
              unsubscribedStudentsByMonthPlatinum:
                teacherStatistics.unsubscribedStudentsByMonthPlatinum
            }}
            totalRevenueGenerated={teacherStatistics.totalRevenueGenerated}
            totalRevenueGeneratedSilver={
              teacherStatistics.totalRevenueGeneratedSilver
            }
            totalRevenueGeneratedGold={
              teacherStatistics.totalRevenueGeneratedGold
            }
            totalRevenueGeneratedPlatinum={
              teacherStatistics.totalRevenueGeneratedPlatinum
            }
          />
        )}
      </div>
    </>
  )
}

export default Home
