import FuseLoading from "@fuse/core/FuseLoading"
import FusePageCarded from "@fuse/core/FusePageCarded"
import { useDeepCompareEffect } from "@fuse/hooks"
import Button from "@mui/material/Button"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import Typography from "@mui/material/Typography"
import { motion } from "framer-motion"
import { SyntheticEvent, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "app/store"
import { Link, useParams } from "react-router-dom"
import _ from "@lodash"
import { FormProvider, useForm } from "react-hook-form"
import * as yup from "yup"
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery"
import * as React from "react"
import ProductHeader from "./FormHeader"
import { Controller, useFormContext } from "react-hook-form"
import TextField from "@mui/material/TextField"
import { ApiServices } from "src/services/ApiServices"
import { selectUser } from "app/store/user/userSlice"
import { MenuItem, Select, InputLabel, FormControl } from "@mui/material"
import { UserTypes } from "../types/users"
import Box from "@mui/system/Box"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import Avatar from "@mui/material/Avatar"
import IconButton from "@mui/material/IconButton"

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  name: yup
    .string()
    .required("You must enter a product name")
    .min(5, "The product name must be at least 5 characters")
})

type FormValues = {
  firstName: string
  lastName: string
  email: string
  password: string
  phone: number
  address: string
  city: string
  aboutMe: string
  birthDate: string
  gender: string
  photos: string
  userImage: File
}

/**
 * The product page.
 */
function UsersForm() {
  const { token } = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"))

  const routeParams = useParams()
  const [tabValue, setTabValue] = useState(0)
  const [noProduct, setNoProduct] = useState(false)

  const methods = useForm<FormValues>({
    mode: "onChange"
  })

  const { control, formState, register, setValue } = methods

  useDeepCompareEffect(() => {
    function updateProductState() {
      const { id, update } = routeParams

      if (id !== "new") {
        ApiServices.getUserById(token, Number(id))
          .then((res) => {
            const {
              firstName,
              lastName,
              email,
              address,
              gender,
              aboutMe,
              birthDate,
              city,
              phone,
              photos
            } = res.data.data

            setValue("firstName", firstName)
            setValue("lastName", lastName)
            setValue("email", email)
            setValue("address", address)
            setValue("city", city)
            setValue("phone", phone)
            setValue("gender", gender)
            setValue("aboutMe", aboutMe)
            // Birth Date
            const bday = new Date(birthDate)
            const year = bday.getFullYear()
            const month = String(bday.getMonth() + 1).padStart(2, "0")
            const day = String(bday.getDate()).padStart(2, "0")
            const formattedDate = `${year}-${month}-${day}`
            setValue("birthDate", formattedDate)
            setValue(
              "photos",
              `https://cpp-photo-bucket.s3.eu-north-1.amazonaws.com/${photos[0]?.s3Url}`
            )
          })
          .catch((err) => {
            console.log(err)
            setNoProduct(true)
          })
      }
    }

    updateProductState()
  }, [dispatch, routeParams])

  /**
   * Tab Change
   */
  function handleTabChange(event: SyntheticEvent, value: number) {
    setTabValue(value)
  }
  /**
   * Show Message if the requested products is not exists
   */
  if (noProduct) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-col flex-1 items-center justify-center h-full"
      >
        <Typography color="text.secondary" variant="h5">
          There is no such User!
        </Typography>
        <Button
          className="mt-24"
          component={Link}
          variant="outlined"
          to="/users"
          color="inherit"
        >
          Go to Products Page
        </Button>
      </motion.div>
    )
  }

  /**
   * Wait while product data is loading and form is setted
   */
  // if (
  //   _.isEmpty(form) ||
  //   (product && routeParams.id !== product.id && routeParams.id !== "new")
  // ) {
  //   return <FuseLoading />;
  // }

  return (
    <>
      <input {...register("userImage")} type="file" className="hidden" />
      <FormProvider {...methods}>
        <FusePageCarded
          header={<ProductHeader />}
          content={
            <>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                classes={{ root: "w-full h-64 border-b-1" }}
              >
                <Tab className="h-64" label="User Profile" />
              </Tabs>
              <div className="p-16 sm:p-24 max-w-3xl">
                <div className={tabValue !== 0 ? "hidden" : ""}>
                  <div>
                    {/* Profile Image */}
                    <div className="w-full">
                      <div className="flex flex-auto items-end mb-8">
                        <Controller
                          control={control}
                          name="photos"
                          render={({ field: { onChange, value } }) => (
                            <Box
                              sx={{
                                borderWidth: 4,
                                borderStyle: "solid",
                                borderColor: "background.paper"
                              }}
                              className="relative flex items-center justify-center w-128 h-128 rounded-full overflow-hidden"
                            >
                              <div className="absolute inset-0 bg-black bg-opacity-50 z-10" />
                              <div className="absolute inset-0 flex items-center justify-center z-20">
                                <div>
                                  <label
                                    htmlFor="button-avatar"
                                    className="flex p-8 cursor-pointer"
                                  >
                                    <input
                                      accept="image/*"
                                      className="hidden"
                                      id="button-avatar"
                                      type="file"
                                      onChange={async (e) => {
                                        function readFileAsync() {
                                          return new Promise(
                                            (resolve, reject) => {
                                              const file = e?.target?.files?.[0]
                                              if (!file) {
                                                return
                                              }
                                              setValue("userImage", file)
                                              const reader: FileReader =
                                                new FileReader()

                                              reader.onload = () => {
                                                if (
                                                  typeof reader.result ===
                                                  "string"
                                                ) {
                                                  resolve(
                                                    `data:${
                                                      file.type
                                                    };base64,${btoa(
                                                      reader.result
                                                    )}`
                                                  )
                                                } else {
                                                  reject(
                                                    new Error(
                                                      "File reading did not result in a string."
                                                    )
                                                  )
                                                }
                                              }

                                              reader.onerror = reject

                                              reader.readAsBinaryString(file)
                                            }
                                          )
                                        }

                                        const newImage = await readFileAsync()

                                        onChange(newImage)
                                      }}
                                    />
                                    <FuseSvgIcon className="text-white">
                                      heroicons-outline:camera
                                    </FuseSvgIcon>
                                  </label>
                                </div>
                                <div>
                                  <IconButton
                                    onClick={() => {
                                      onChange("")
                                    }}
                                  >
                                    <FuseSvgIcon className="text-white">
                                      heroicons-solid:trash
                                    </FuseSvgIcon>
                                  </IconButton>
                                </div>
                              </div>
                              <Avatar
                                sx={{
                                  backgroundColor: "background.default",
                                  color: "text.secondary"
                                }}
                                className="object-cover w-full h-full text-64 font-bold"
                                src={`${value}`}
                                alt={"User"}
                              >
                                {"U"}
                              </Avatar>
                            </Box>
                          )}
                        />
                      </div>
                    </div>
                    {/* First Name */}
                    <Controller
                      name="firstName"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mt-8 mb-16"
                          required
                          label="First Name"
                          autoFocus
                          id="firstName"
                          variant="outlined"
                          fullWidth
                          {...register("firstName", {
                            required: {
                              value: true,
                              message: "firstName is required"
                            }
                          })}
                          error={!!formState.errors.firstName}
                          helperText={
                            formState.errors?.firstName?.message as string
                          }
                          InputLabelProps={{
                            shrink: !!field.value
                          }}
                        />
                      )}
                    />
                    {/* Last Name */}
                    <Controller
                      name="lastName"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mt-8 mb-16"
                          required
                          label="Last Name"
                          id="lastName"
                          variant="outlined"
                          fullWidth
                          {...register("lastName", {
                            required: {
                              value: true,
                              message: "lastName is required"
                            }
                          })}
                          error={!!formState.errors.lastName}
                          helperText={
                            formState.errors?.lastName?.message as string
                          }
                          InputLabelProps={{
                            shrink: !!field.value
                          }}
                        />
                      )}
                    />
                    {/* Email */}
                    <Controller
                      control={control}
                      name="email"
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          className="mt-8 mb-16"
                          {...field}
                          label="Email"
                          placeholder="Email"
                          id="email"
                          {...register("email", {
                            required: "Email is Required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "invalid email address"
                            }
                          })}
                          error={!!formState.errors.email}
                          helperText={formState.errors?.email?.message}
                          variant="outlined"
                          required
                          fullWidth
                          InputLabelProps={{
                            shrink: !!field.value
                          }}
                        />
                      )}
                    />
                    {/* Phone */}
                    <Controller
                      control={control}
                      name="phone"
                      defaultValue={0}
                      render={({ field }) => (
                        <TextField
                          className="mt-8 mb-16"
                          {...field}
                          label="Phone"
                          placeholder="Phone"
                          id="phone"
                          type="tel"
                          {...register("phone", {
                            required: "Phone Number is Required"
                          })}
                          error={!!formState.errors.phone}
                          helperText={formState.errors?.phone?.message}
                          variant="outlined"
                          required
                          fullWidth
                          InputLabelProps={{
                            shrink: !!field.value
                          }}
                        />
                      )}
                    />
                    {/* Address */}
                    <Controller
                      control={control}
                      name="address"
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          className="mt-8 mb-16"
                          {...field}
                          label="Address"
                          placeholder="Address"
                          id="address"
                          {...register("address", {
                            required: "Address is Required"
                          })}
                          error={!!formState.errors.address}
                          helperText={formState.errors?.address?.message}
                          variant="outlined"
                          required
                          fullWidth
                          InputLabelProps={{
                            shrink: !!field.value
                          }}
                        />
                      )}
                    />
                    {/* City */}
                    <Controller
                      control={control}
                      name="city"
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          className="mt-8 mb-16"
                          {...field}
                          label="City Name"
                          placeholder="City Name"
                          id="city"
                          {...register("city", {
                            required: "City Name is Required"
                          })}
                          error={!!formState.errors.city}
                          helperText={formState.errors?.city?.message}
                          variant="outlined"
                          required
                          fullWidth
                          InputLabelProps={{
                            shrink: !!field.value
                          }}
                        />
                      )}
                    />
                    {/* Gender */}
                    <Controller
                      name="gender"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          className="mt-8 mb-16"
                        >
                          <InputLabel id="gender-label">Gender</InputLabel>
                          <Select
                            {...field}
                            value={field.value || []}
                            onChange={(e) => field.onChange(e.target.value)}
                            fullWidth
                            className=""
                            variant="outlined"
                            labelId="gender-label"
                            label="Gender"
                            {...register("gender", {
                              required: {
                                value: true,
                                message: "Please Select Your Gender"
                              }
                            })}
                          >
                            <MenuItem value={"MALE"}>Male</MenuItem>
                            <MenuItem value={"FEMALE"}>Female</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                    {/* Birth Date */}
                    <Controller
                      name={`birthDate`}
                      defaultValue={""}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mt-8 mb-16"
                          required
                          label={`Birth Date`}
                          name={`birthDate`}
                          variant="outlined"
                          type="date"
                          fullWidth
                          {...register("birthDate", {
                            required: {
                              value: true,
                              message: "Please Select Your Date Of Birth"
                            }
                          })}
                          error={!!formState.errors.birthDate}
                          helperText={formState.errors?.birthDate?.message}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                    />
                    {/* About me */}
                    <Controller
                      name={`aboutMe`}
                      defaultValue={""}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mt-8 mb-16"
                          required
                          label={`About Me`}
                          name={`aboutMe`}
                          variant="outlined"
                          type="text"
                          fullWidth
                          {...register("aboutMe", {
                            required: {
                              value: true,
                              message: "Please fill About Me"
                            }
                          })}
                          error={!!formState.errors.birthDate}
                          helperText={formState.errors?.birthDate?.message}
                          InputLabelProps={{
                            shrink: !!field.value
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </>
          }
          scroll={isMobile ? "normal" : "content"}
        />
      </FormProvider>
    </>
  )
}

export default UsersForm
