import React, { useState } from "react";
import ChangePassword from "./ChangePassword";
import ChangeProfile from "./ChangeProfile";
import { StudentProfileTypes } from "./types";

interface ProfileEditTypes {
  handleEdit: () => void;
  userData: StudentProfileTypes;
  loadData: () => void;
}

/**
 * The contact form.
 */
const ProfileEdit: React.FC<ProfileEditTypes> = ({
  handleEdit,
  userData,
  loadData,
}) => {
  return (
    <ChangeProfile
      handleEdit={handleEdit}
      userData={userData}
      loadData={loadData}
    />
  );
};

export default ProfileEdit;
