import FuseScrollbars from "@fuse/core/FuseScrollbars";
import _ from "@lodash";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useAppSelector } from "app/store";
import FuseLoading from "@fuse/core/FuseLoading";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import * as React from "react";
import ProductsTableHead from "./SubjectsTableHead";
import { subjectType } from "./types/SubjectsTypes";
import { ApiServices } from "src/services/ApiServices";
import { useNavigate } from "react-router-dom";
import { selectUser } from "app/store/user/userSlice";
import Box from "@mui/material/Box";
import { IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import swal from "sweetalert";
import NotificationTemplate from "app/theme-layouts/shared-components/notificationPanel/NotificationTemplate";
import NotificationModel from "app/theme-layouts/shared-components/notificationPanel/models/NotificationModel";
import { useSnackbar } from "notistack";

interface SubjectTableTypes {
  searchText: string;
}

/**
 * The products table.
 */
function SubjectsTable(props: SubjectTableTypes) {
  const { searchText } = props;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<string[]>([]);
  const [data, setData] = useState<subjectType[]>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableOrder, setTableOrder] = useState<{
    direction: "asc" | "desc";
    id: string;
  }>({
    direction: "asc",
    id: "",
  });

  function handleRequestSort(
    event: MouseEvent<HTMLSpanElement>,
    property: string
  ) {
    const newOrder: {
      direction: "asc" | "desc";
      id: string;
    } = { id: property, direction: "desc" };

    if (tableOrder.id === property && tableOrder.direction === "desc") {
      newOrder.direction = "asc";
    }

    setTableOrder(newOrder);
  }

  //

  const [anchorElArr, setAnchorElArr] = useState(Array(data.length).fill(null));

  const handleMenuOpen = (event, index) => {
    const newAnchorElArr = [...anchorElArr];
    newAnchorElArr[index] = event.currentTarget;
    setAnchorElArr(newAnchorElArr);
  };

  const handleMenuClose = () => {
    setAnchorElArr(Array(data.length).fill(null));
  };

  //

  function handleSelectAllClick(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      setSelected(data.map((n) => n.id));
      return;
    }

    setSelected([]);
  }

  function handleDeselect() {
    setSelected([]);
  }

  function handleClick(id: string) {
    navigate(`/subject/${id}`);
  }

  function handleCheck(event: ChangeEvent<HTMLInputElement>, id: string) {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) {
    setPage(+page);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value);
  }

  const LoadPageData = () => {
    ApiServices.getAllSubjects().then((res) => {
      setData(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    LoadPageData();
  }, []);

  const { token } = useAppSelector(selectUser);

  const handleDeleteItemById = (id: string) => {
    swal({
      title: "Delete",
      text: "Are you sure want to delete permanently",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then((will) => {
      if (will) {
        ApiServices.removeSubjectById(token, id)
          .then((res) => {
            enqueueSnackbar(res.data.details, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });

            LoadPageData();
            handleDeselect();
          })
          .catch((err) => {
            console.log(err);
            enqueueSnackbar(err.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          });
      }
    });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <FuseLoading />
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-1 items-center justify-center h-full"
      >
        <Typography color="text.secondary" variant="h5">
          Aucune matiere disponible pour le moment 📚.
        </Typography>
      </motion.div>
    );
  }

  return (
    <>
      {/* Table Here */}

      <div className="w-full flex flex-col min-h-full">
        <FuseScrollbars className="grow overflow-x-auto">
          <Table stickyHeader className="min-w-xl" aria-labelledby="tableTitle">
            <ProductsTableHead
              selectedProductIds={selected}
              tableOrder={tableOrder}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />

            <TableBody>
              {_.orderBy(
                data,
                ["name"],
                [tableOrder.direction as "asc" | "desc"]
              )
                .filter((subject) =>
                  subject.name.toLowerCase().includes(searchText.toLowerCase())
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, index) => {
                  const isSelected = selected.indexOf(n.id) !== -1;
                  return (
                    <TableRow
                      className="h-72 cursor-pointer"
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell
                        className="w-40 md:w-64 text-center"
                        padding="none"
                      >
                        <Checkbox
                          checked={isSelected}
                          onClick={(event) => event.stopPropagation()}
                          onChange={(event) => handleCheck(event, n.id)}
                        />
                      </TableCell>

                      <TableCell
                        className="w-52 px-4 md:px-0"
                        component="th"
                        scope="row"
                        padding="none"
                      >
                        {n?.image?.length > 0 ? (
                          <img
                            className="w-full block rounded"
                            src={n.image}
                            alt={n.name}
                          />
                        ) : (
                          <img
                            className="w-full block rounded"
                            src="assets/images/apps/ecommerce/product-image-placeholder.png"
                            alt={n.name}
                          />
                        )}
                      </TableCell>

                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                      >
                        {n.name}
                      </TableCell>

                      {/* Action */}
                      <TableCell
                        className="p-4"
                        component="th"
                        scope="row"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Box className="flex items-center rounded-full justify-center">
                          <IconButton
                            aria-controls={`simple-menu-${index}`}
                            aria-haspopup="true"
                            onClick={(e) => handleMenuOpen(e, index)}
                            size="large"
                            className="hover:bg-transparent"
                          >
                            <FuseSvgIcon>
                              heroicons-outline:dots-horizontal
                            </FuseSvgIcon>
                          </IconButton>
                          <Menu
                            id={`simple-menu-${index}`}
                            anchorEl={anchorElArr[index]}
                            keepMounted
                            open={Boolean(anchorElArr[index])}
                            onClose={handleMenuClose}
                          >
                            <MenuItem
                              onClick={() => {
                                handleMenuClose();
                                handleClick(n.id);
                              }}
                            >
                              <ListItemIcon className="min-w-40">
                                <FuseSvgIcon>
                                  heroicons-outline:pencil
                                </FuseSvgIcon>
                              </ListItemIcon>
                              Modifier
                            </MenuItem>
                            {/*  */}
                            <MenuItem
                              onClick={() => {
                                handleMenuClose();
                                handleDeleteItemById(n.id);
                              }}
                            >
                              <ListItemIcon className="min-w-40">
                                <FuseSvgIcon>
                                  heroicons-outline:trash
                                </FuseSvgIcon>
                              </ListItemIcon>
                              Delete
                            </MenuItem>
                          </Menu>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </FuseScrollbars>

        <TablePagination
          className="shrink-0 border-t-1"
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}

export default SubjectsTable;
