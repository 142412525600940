import FuseScrollbars from "@fuse/core/FuseScrollbars"
import _ from "lodash"
import Checkbox from "@mui/material/Checkbox"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { motion } from "framer-motion"
import { ChangeEvent, MouseEvent, useEffect, useState } from "react"
import { useAppSelector } from "app/store"
import FuseLoading from "@fuse/core/FuseLoading"
import * as React from "react"
import RefundTableHead from "./RefundTableHead"
import { ApiServices } from "src/services/ApiServices"
import { RefundTypes } from "./types/RefundTypes"
import TagChip from "app/shared-components/TagChip"
import { selectUser } from "app/store/user/userSlice"
import TagChipForPaymentStatus from "app/shared-components/TagChipForPaymentStatus"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Box from "@mui/material/Box"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import { useSnackbar } from "notistack"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Button from "@mui/material/Button"

interface SubjectTableTypes {
  searchText: string
}

const getPaymentMethodInFrench = (method: string | null) => {
  switch (method) {
    case "STRIPE":
      return "Carte bancaire 💳"
    case "WIRE_TRANSFER":
      return "Virement bancaire 💸"
    default:
      return "Inconnu"
  }
}

function RefundTable(props: SubjectTableTypes) {
  const { searchText } = props

  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState<string[]>([])
  const [data, setData] = useState<RefundTypes[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [tableOrder, setTableOrder] = useState<{
    direction: "asc" | "desc"
    id: string
  }>({
    direction: "asc",
    id: ""
  })

  const [anchorElArr, setAnchorElArr] = useState(Array(data.length).fill(null))
  const [openModal, setOpenModal] = useState(false)
  const [proofPhotoUrls, setProofPhotoUrls] = useState<string[]>([])
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const [selectedEnrollmentId, setSelectedEnrollmentId] = useState<
    number | null
  >(null)

  const handleMenuOpen = (event, index) => {
    const newAnchorElArr = [...anchorElArr]
    newAnchorElArr[index] = event.currentTarget
    setAnchorElArr(newAnchorElArr)
  }

  const handleMenuClose = () => {
    setAnchorElArr(Array(data.length).fill(null))
  }

  const handleRequestSort = (
    event: MouseEvent<HTMLSpanElement>,
    property: string
  ) => {
    const newOrder: {
      direction: "asc" | "desc"
      id: string
    } = { id: property, direction: "desc" }

    if (tableOrder.id === property && tableOrder.direction === "desc") {
      newOrder.direction = "asc"
    }

    setTableOrder(newOrder)
  }

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(data.map((n) => n.enrollmentId.toString()))
      return
    }
    setSelected([])
  }

  const handleDeselect = () => {
    setSelected([])
  }

  const handleCheck = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(+page)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
  }

  const { token } = useAppSelector(selectUser)

  const LoadPageData = () => {
    ApiServices.getPendingCancellations(token).then((res) => {
      setData(res.data.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    LoadPageData()
  }, [])

  const { enqueueSnackbar } = useSnackbar()

  const handleAcceptCancellation = (id: number) => {
    ApiServices.handleRefundConfirm(token, id)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          enqueueSnackbar("Refund request accepted", { variant: "success" })
          LoadPageData()
        } else {
          enqueueSnackbar("Failed to accept refund request", {
            variant: "error"
          })
        }
      })
      .catch((err) => {
        console.error("Failed to accept refund request:", err)
        enqueueSnackbar("Failed to accept refund request", { variant: "error" })
      })
      .finally(() => {
        setConfirmationDialogOpen(false)
      })
  }

  const openConfirmationDialog = (enrollmentId: number) => {
    setSelectedEnrollmentId(enrollmentId)
    setConfirmationDialogOpen(true)
  }

  const closeConfirmationDialog = () => {
    setConfirmationDialogOpen(false)
    setSelectedEnrollmentId(null)
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <FuseLoading />
      </div>
    )
  }

  if (data.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-1 items-center justify-center h-full"
      >
        <Typography color="text.secondary" variant="h5">
          Aucun remboursement en attente.
        </Typography>
      </motion.div>
    )
  }

  return (
    <>
      <div className="w-full flex flex-col min-h-full">
        <FuseScrollbars className="grow overflow-x-auto">
          <Table stickyHeader className="min-w-xl" aria-labelledby="tableTitle">
            <RefundTableHead
              selectedProductIds={selected}
              tableOrder={tableOrder}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {_.orderBy(
                data,
                ["studentFirstName"],
                [tableOrder.direction as "asc" | "desc"]
              )
                .filter((refund) =>
                  refund.studentFirstName
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, index) => {
                  const isSelected =
                    selected.indexOf(n.enrollmentId.toString()) !== -1

                  return (
                    <TableRow
                      className="h-72 cursor-pointer"
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.enrollmentId}
                      selected={isSelected}
                    >
                      <TableCell
                        className="w-40 md:w-64 text-center"
                        padding="none"
                      >
                        <Checkbox
                          checked={isSelected}
                          onClick={(event) => event.stopPropagation()}
                          onChange={(event) =>
                            handleCheck(event, n.enrollmentId.toString())
                          }
                        />
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                      >
                        {n.teacherName} {n.teacherLastName}
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                      >
                        {n.studentFirstName} {n.studentLastName}
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                      >
                        {n.courseName}
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                      >
                        <TagChip
                          category={{
                            name: n.subscription.type,
                            type: n.subscription.type
                          }}
                        />
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                      >
                        {new Date(n.enrollmentDate).toLocaleDateString()}{" "}
                        {new Date(n.enrollmentDate).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit"
                        })}
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                      >
                        {n.cancellationDate
                          ? new Date(n.cancellationDate).toLocaleDateString()
                          : "N/A"}{" "}
                        {n.cancellationDate
                          ? new Date(n.cancellationDate).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit"
                              }
                            )
                          : ""}
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                      >
                        {n.subscription.price} DH
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                      >
                        {getPaymentMethodInFrench(n.paymentMethod)}
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                      >
                        <TagChipForPaymentStatus status={n.paymentStatus} />
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                      >
                        <TagChipForPaymentStatus status={n.enrollmentStatus} />
                      </TableCell>
                      <TableCell
                        className="p-4"
                        component="th"
                        scope="row"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Box className="flex items-center rounded-full justify-center">
                          <IconButton
                            aria-controls={`simple-menu-${index}`}
                            aria-haspopup="true"
                            onClick={(e) => handleMenuOpen(e, index)}
                            size="large"
                            className="hover:bg-transparent"
                          >
                            <FuseSvgIcon>
                              heroicons-outline:dots-horizontal
                            </FuseSvgIcon>
                          </IconButton>
                          <Menu
                            id={`simple-menu-${index}`}
                            anchorEl={anchorElArr[index]}
                            keepMounted
                            open={Boolean(anchorElArr[index])}
                            onClose={handleMenuClose}
                          >
                            <MenuItem
                              onClick={() => {
                                handleMenuClose()
                                openConfirmationDialog(n.enrollmentId)
                              }}
                            >
                              Accepter
                            </MenuItem>
                          </Menu>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </FuseScrollbars>
        <TablePagination
          className="shrink-0 border-t-1"
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      <Dialog
        open={confirmationDialogOpen}
        onClose={closeConfirmationDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Êtes-vous sûr de vouloir accepter cette demande de remboursement ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmationDialog} color="primary">
            Annuler
          </Button>
          <Button
            onClick={() => {
              if (selectedEnrollmentId !== null) {
                handleAcceptCancellation(selectedEnrollmentId)
              }
            }}
            color="primary"
            autoFocus
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default RefundTable
