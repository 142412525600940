import TextField from "@mui/material/TextField";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { motion } from "framer-motion";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { ApiServices } from "src/services/ApiServices";
import { useAppSelector } from "app/store";
import { selectUser } from "app/store/user/userSlice";

import NotificationTemplate from "app/theme-layouts/shared-components/notificationPanel/NotificationTemplate";
import NotificationModel from "app/theme-layouts/shared-components/notificationPanel/models/NotificationModel";
import { useSnackbar } from "notistack";

type platinumTypes = {
  maxStudent: number;
  platinumPrice: number;
};
type silverTypes = {
  maxStudent: number;
  silverPrice: number;
};
type goldTypes = {
  maxStudent: number;
  goldPrice: number;
};
// Local Storage Store
function useLocalStorage(key: string, initialValue: boolean | number) {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === key) {
        setValue(JSON.parse(event.newValue));
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key]);

  const setStoredValue = (newValue) => {
    setValue(newValue);
    localStorage.setItem(key, JSON.stringify(newValue));
  };

  return [value, setStoredValue];
}

function SubscriptionTab() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { token } = useAppSelector(selectUser);

  const Navigate = useNavigate();
  const submitedCourseId = Cookies.get("submitedCourseId");

  const [platinumIsSubmit, setPlatinumIsSubmit] = useLocalStorage(
    "platinumSubmit",
    false
  );
  const [silverIsSubmit, setSilverIsSubmit] = useLocalStorage(
    "silverSubmit",
    false
  );
  const [goldIsSubmit, setGoldIsSubmit] = useLocalStorage("goldSubmit", false);

  const [platinumSubId, setPlatinumSubId] = useLocalStorage("platinumSubId", 0);
  const [silverSubId, setSilverSubId] = useLocalStorage("silverSubId", 0);
  const [goldSubId, setGoldSubId] = useLocalStorage("goldSubId", 0);

  // IsSubmiting
  const [platinumIsSubmiting, setPlatinumIsSubmiting] = useState(false);
  const [silverIsSubmiting, setSilverIsSubmiting] = useState(false);
  const [goldIsSubmiting, setGoldIsSubmiting] = useState(false);

  useEffect(() => {
    if (goldIsSubmit && silverIsSubmit && platinumIsSubmit) {
      Navigate("/course/new/groups");
    }
  }, [goldIsSubmit, silverIsSubmit, platinumIsSubmit]);

  //
  const platinumMethods = useForm<platinumTypes>({
    mode: "onChange",
  });
  const silverMethods = useForm<silverTypes>({
    mode: "onChange",
  });
  const goldMethods = useForm<goldTypes>({
    mode: "onChange",
  });
  //
  const [platinumErr, setPlatinumErr] = useState("");
  const handlePlatinumSubmit = (data) => {
    setPlatinumIsSubmiting(true);
    const storeData = {
      type: "PLATINUM",
      maxStudents: data.maxStudents,
      courseId: submitedCourseId,
      price: data.price,
    };
    ApiServices.storeSubscription(token, storeData)
      .then((res) => {
        setPlatinumIsSubmiting(false);
        if (res.data.status === "SUCCESS") {
          setPlatinumSubId(res.data.data.id);
          setPlatinumIsSubmit(true);
          ApiServices.coursePublishStatus(token, submitedCourseId);

          enqueueSnackbar(res.data.details, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }

        if (res.data.status === "FAIL") {
          setPlatinumErr(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setPlatinumIsSubmiting(false);
      });
  };
  //
  const [silverErr, setSilverErr] = useState("");
  const handleSilverSubmit = (data) => {
    setSilverIsSubmiting(true);
    const storeData = {
      type: "SILVER",
      maxStudents: data.maxStudents,
      courseId: submitedCourseId,
      price: data.price,
    };
    ApiServices.storeSubscription(token, storeData)
      .then((res) => {
        setSilverIsSubmiting(false);
        if (res.data.status === "SUCCESS") {
          setSilverSubId(res.data.data.id);
          setSilverIsSubmit(true);
          ApiServices.coursePublishStatus(token, submitedCourseId);

          enqueueSnackbar(res.data.details, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }

        if (res.data.status === "FAIL") {
          setSilverErr(res.data.error);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setSilverIsSubmiting(false);
        console.log(err);
      });
  };
  //
  const [goldErr, setGoldErr] = useState("");
  const handleGoldSubmit = (data) => {
    setGoldIsSubmiting(true);
    const storeData = {
      type: "GOLD",
      maxStudents: data.maxStudents,
      courseId: submitedCourseId,
      price: data.price,
    };
    ApiServices.storeSubscription(token, storeData)
      .then((res) => {
        setGoldIsSubmiting(false);
        if (res.data.status === "SUCCESS") {
          setGoldSubId(res.data.data.id);
          setGoldIsSubmit(true);
          ApiServices.coursePublishStatus(token, submitedCourseId);

          enqueueSnackbar(res.data.details, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }

        if (res.data.status === "FAIL") {
          setGoldErr(res.data.error);
        }
      })
      .catch((err) => {
        setGoldIsSubmiting(false);
        enqueueSnackbar(err.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        console.log(err);
      });
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 justify-between w-full gap-12">
      <FormProvider {...silverMethods}>
        <form onSubmit={silverMethods.handleSubmit(handleSilverSubmit)}>
          <div className="border py-12 px-14 rounded-8 flex flex-col">
            <h2 className="text-2xl mb-8">Silver</h2>

            <Controller
              name="maxStudents"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-8 mb-16"
                  required
                  label="Max d'etudiants"
                  id="maxStudents"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              )}
            />

            <Controller
              name="price"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-8 mb-16"
                  required
                  label="Prix Silver"
                  id="price"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              )}
            />
            {/*  */}
            <span className="text-red-500 text-sm py-4">{silverErr}</span>
            {/*  */}
            <div className="px-8">
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                className="flex justify-end"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className="px-16 py-2"
                  fullWidth
                  disabled={silverIsSubmit || silverIsSubmiting}
                >
                  {silverIsSubmiting ? "Création..." : "Créer"}
                </Button>
              </motion.div>
            </div>
          </div>
        </form>
      </FormProvider>

      <FormProvider {...goldMethods}>
        <form onSubmit={goldMethods.handleSubmit(handleGoldSubmit)}>
          <div className="border py-12 px-14 rounded-8 flex flex-col">
            <h2 className="text-2xl mb-8">Gold</h2>

            <Controller
              name="maxStudents"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-8 mb-16"
                  required
                  label="Max d'etudiants"
                  id="maxStudents"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              )}
            />

            <Controller
              name="price"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-8 mb-16"
                  required
                  label="Prix Gold"
                  id="price"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              )}
            />
            {/*  */}
            <span className="text-red-500 text-sm py-4">{goldErr}</span>
            {/*  */}
            <div className="px-8">
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                className="flex justify-end"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className="px-16 py-2"
                  fullWidth
                  disabled={goldIsSubmit || goldIsSubmiting}
                >
                  {goldIsSubmiting ? "Création .." : "Créer"}
                </Button>
              </motion.div>
            </div>
          </div>
        </form>
      </FormProvider>

      <FormProvider {...platinumMethods}>
        <form onSubmit={platinumMethods.handleSubmit(handlePlatinumSubmit)}>
          <div className="border py-12 px-14 rounded-8 flex flex-col">
            <h2 className="text-2xl mb-8">Platinum</h2>

            <Controller
              name="maxStudents"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-8 mb-16"
                  required
                  label="Max d'etudiants"
                  id="maxStudents"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              )}
            />

            {/* platinumPrice */}
            <Controller
              name="price"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-8 mb-16"
                  required
                  label="Prix Platinum"
                  id="price"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              )}
            />
            {/*  */}
            <span className="text-red-500 text-sm py-4">{platinumErr}</span>
            {/*  */}
            <div className="px-8">
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                className="flex justify-end"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className="px-16 py-2"
                  fullWidth
                  disabled={platinumIsSubmit || platinumIsSubmiting}
                >
                  {platinumIsSubmiting ? "Création.." : "Créer"}
                </Button>
              </motion.div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default SubscriptionTab;
