import React, { useState, useEffect } from "react"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import ReactApexChart from "react-apexcharts"
import { useTheme } from "@mui/material/styles"
import { ApexOptions } from "apexcharts"

interface WaveChartProps {
  registrationsByRole: Record<string, { STUDENT: number; TEACHER: number }>
}

const WaveChart: React.FC<WaveChartProps> = ({ registrationsByRole }) => {
  const theme = useTheme()
  const [awaitRender, setAwaitRender] = useState(true)

  useEffect(() => {
    setAwaitRender(false)
  }, [])

  if (awaitRender) {
    return null
  }

  // Convert the keys (months) of registrationsByRole to an array
  const months = Object.keys(registrationsByRole)

  // Extracting data for teachers and students per month
  const teacherData = months.map((month) => registrationsByRole[month].TEACHER)
  const studentData = months.map((month) => registrationsByRole[month].STUDENT)

  const chartOptions: ApexOptions = {
    chart: {
      fontFamily: "inherit",
      foreColor: "inherit",
      height: "100%",
      type: "line",
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: [theme.palette.primary.main, theme.palette.secondary.main],
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
      background: {
        borderWidth: 0
      }
    },
    grid: {
      borderColor: theme.palette.divider
    },
    legend: {
      show: true,
      position: "top"
    },
    plotOptions: {
      bar: {
        columnWidth: "50%"
      }
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.75
        }
      }
    },
    stroke: {
      width: [3, 0]
    },
    tooltip: {
      followCursor: true,
      theme: theme.palette.mode
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        color: theme.palette.divider
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      },
      tooltip: {
        enabled: false
      },
      // Set the categories to the months array
      categories: months
    },
    yaxis: {
      labels: {
        offsetX: -16,
        style: {
          colors: theme.palette.text.secondary
        }
      }
    }
  }

  return (
    <Paper className="flex flex-col flex-auto p-24 shadow rounded-2xl overflow-hidden">
      <div className="flex flex-col sm:flex-row items-start justify-between">
        <Typography className="text-lg font-medium tracking-tight leading-6 truncate">
          Nombre d'inscriptions par mois (Étudiants et Enseignants)
        </Typography>
      </div>
      <div className="grid  gap-24 w-full mt-32 sm:mt-16">
        <div className="flex flex-col flex-auto">
          <div className="flex flex-col flex-auto">
            <ReactApexChart
              className="flex-auto w-full"
              options={chartOptions}
              series={[
                { name: "Etudiants", type: "line", data: studentData },
                { name: "Professeurs", type: "bar", data: teacherData }
              ]}
              height={320}
            />
          </div>
        </div>
      </div>
    </Paper>
  )
}

export default WaveChart
