import Button from "@mui/material/Button"
import { Link } from "react-router-dom"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { Controller, useForm } from "react-hook-form"
import _ from "@lodash"
import TextField from "@mui/material/TextField"
import { useState, useEffect } from "react" // Import de useEffect pour gérer le chargement des données utilisateur
import { ApiServices } from "src/services/ApiServices"
import { useSnackbar } from "notistack"
type formValuesType = {
  name: string
  email: string
  subject: string
  message: string
}

const defaultValues = { name: "", email: "", subject: "", message: "" }

interface HelpCenterSupportProps {
  userData?: { firstName: string; lastName: string; email: string } // Définition de userData comme optionnel
}

/**
 * The help center support.
 */
function HelpCenterSupport({ userData }: HelpCenterSupportProps) {
  const { control, handleSubmit, watch, formState, reset, setValue } = useForm({
    mode: "onChange",
    defaultValues
  })
  const { isValid, dirtyFields, errors } = formState

  const form = watch()
  const [submissionStatus, setSubmissionStatus] = useState<string | null>(null) // État pour gérer le statut de la soumission
  const { enqueueSnackbar } = useSnackbar() // Utilisation de useSnackbar pour afficher les notifications

  // Utiliser useEffect pour pré-remplir les champs avec les données de l'utilisateur connecté
  useEffect(() => {
    if (userData) {
      setValue("name", userData.firstName + " " + userData.lastName)
      setValue("email", userData.email)
    }
  }, [userData, setValue])

  async function onSubmit(data: formValuesType) {
    try {
      const token = "votre_token_ici" // Remplacez par votre logique pour obtenir le token
      const response = await ApiServices.postSupportMessage(token, data)

      if (response.data.status === "SUCCESS") {
        enqueueSnackbar("Message envoyé avec succès !", { variant: "success" })
        reset(defaultValues) // Réinitialisation du formulaire en cas de succès
      } else {
        enqueueSnackbar("Échec de l'envoi du message", { variant: "error" })
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du message", error)
      enqueueSnackbar("Erreur lors de l'envoi du message", { variant: "error" })
    }
  }

  if (_.isEmpty(form)) {
    return null
  }

  return (
    <div className="flex flex-col items-center p-24 sm:p-40 container">
      <div className="flex flex-col w-full max-w-4xl">
        <div className="sm:mt-32">
          <Button
            component={Link}
            to="/apps/help-center"
            color="secondary"
            startIcon={
              <FuseSvgIcon>heroicons-outline:arrow-narrow-left</FuseSvgIcon>
            }
          >
            Revenir à votre espace
          </Button>
        </div>
        <div className="mt-8 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight">
          Contactez le support
        </div>

        <Paper className="mt-32 sm:mt-48 p-24 pb-28 sm:p-40 sm:pb-28 rounded-2xl">
          <form onSubmit={handleSubmit(onSubmit)} className="px-0 sm:px-24">
            <div className="mb-24">
              <Typography className="text-2xl font-bold tracking-tight">
                Soumettez votre demande
              </Typography>
              <Typography color="text.secondary">
                Votre demande sera traitée et notre équipe de support vous
                répondra sous 24 heures.
              </Typography>
            </div>
            <div className="space-y-32">
              <Controller
                control={control}
                name="name"
                rules={{ required: "Vous devez entrer un nom" }}
                render={({ field }) => (
                  <TextField
                    className="w-full"
                    {...field}
                    label="Nom"
                    placeholder="Nom"
                    id="name"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />

              <Controller
                control={control}
                name="email"
                rules={{
                  required: "Vous devez entrer un email",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Vous devez entrer un email valide"
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mt-16 w-full"
                    label="Email"
                    placeholder="Email"
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name="subject"
                rules={{ required: "Vous devez entrer un sujet" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mt-16 w-full"
                    label="Sujet"
                    placeholder="Sujet"
                    variant="outlined"
                    fullWidth
                    error={!!errors.subject}
                    helperText={errors?.subject?.message}
                    required
                  />
                )}
              />

              <Controller
                name="message"
                control={control}
                rules={{ required: "Vous devez entrer un message" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Message"
                    className="mt-16 w-full"
                    margin="normal"
                    multiline
                    minRows={4}
                    variant="outlined"
                    error={!!errors.message}
                    helperText={errors?.message?.message}
                    required
                  />
                )}
              />
            </div>
            <div className="flex items-center justify-end mt-32">
              <Button className="mx-8">Annuler</Button>
              <Button
                className="mx-8"
                variant="contained"
                color="secondary"
                disabled={_.isEmpty(dirtyFields) || !isValid}
                type="submit"
              >
                Envoyer
              </Button>
            </div>
          </form>
          {submissionStatus && (
            <Typography
              className="mt-16"
              color={submissionStatus.includes("succès") ? "green" : "red"}
            >
              {submissionStatus}
            </Typography>
          )}
        </Paper>
      </div>
    </div>
  )
}

export default HelpCenterSupport
