import _ from "@lodash";
import clsx from "clsx";
import { BadgeColorTypes } from "src/data/global_types";

/**
 * The order statuses.
 */
export const orderStatuses: BadgeColorTypes[] = [
  {
    id: "1",
    name: "SILVER",
    color: "bg-[#A8A9AD] text-white",
  },
  {
    id: "2",
    name: "PLATINUM",
    color: "bg-[#232121] text-white",
  },
  {
    id: "3",
    name: "GOLD",
    color: "bg-[#DAA511] text-white",
  },
];

/**
 * The orders status properties.
 */
type OrdersStatusProps = {
  name: string;
};

/**
 * The orders status component.
 */
function PackageBadge(props: OrdersStatusProps) {
  const { name } = props;

  return (
    <div
      className={clsx(
        "inline text-12 font-semibold py-4 px-12 rounded-full truncate",
        _.find(orderStatuses, { name }).color
      )}
    >
      {name}
    </div>
  );
}

export default PackageBadge;
