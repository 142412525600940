import { useAppSelector } from "app/store";
import { selectUserRole } from "app/store/user/userSlice";
// Profiles
import AdminResetPassword from "./AdminProfile/ChangePassword";
import TeacherResetPassword from "./TeacherProfile/ChangePassword";
import StudentResetPassword from "./StudentProfile/ChangePassword";

const ResetPassword = () => {
  const userRole = useAppSelector(selectUserRole);

  const renderProfileRole = () => {
    switch (userRole) {
      case "ADMIN":
        return <AdminResetPassword />;
      case "TEACHER":
        return <TeacherResetPassword />;
      case "STUDENT":
        return <StudentResetPassword />;
      default:
        return <div>Error: Invalid User Role</div>;
    }
  };

  return <>{renderProfileRole()}</>;
};

export default ResetPassword;
