import { Chip } from "@mui/material"
import { darken, lighten } from "@mui/system"

const getColorAndBackgroundColorForPaymentStatus = (status: string) => {
  switch (status) {
    // Statuts existants
    case "PENDING":
      return {
        color: "#212529", // Texte sombre pour un meilleur contraste
        backgroundColor: "#ffc107" // Jaune pour en attente
      }
    case "PAID":
      return {
        color: "#FFFFFF", // Texte blanc pour payé
        backgroundColor: "#28a745" // Vert pour payé
      }
    case "CANCELLED":
      return {
        color: "#FFFFFF", // Texte blanc pour annulé
        backgroundColor: "#dc3545" // Rouge vif pour annulé
      }
    case "REFUNDED":
      return {
        color: "#FFFFFF", // Texte blanc pour remboursé
        backgroundColor: "#17a2b8" // Cyan pour remboursé
      }
    case "FAILED":
      return {
        color: "#FFFFFF", // Texte blanc pour échoué
        backgroundColor: "#ff4d4d" // Rouge plus doux pour échoué
      }
    case "PENDING_FOR_VALIDATION":
      return {
        color: "#212529", // Texte sombre pour meilleur contraste
        backgroundColor: "#ffc107" // Jaune pour en attente de validation
      }
    case "PENDING_FOR_CANCELLATION":
      return {
        color: "#212529", // Texte sombre pour meilleur contraste
        backgroundColor: "#ff9800" // Orange pour en attente d'annulation
      }
    case "ACTIVE":
      return {
        color: "#FFFFFF", // Texte blanc pour actif
        backgroundColor: "#28a745" // Vert pour actif
      }
    case "PENDING_CANCELLATION":
      return {
        color: "#212529", // Texte sombre pour meilleur contraste
        backgroundColor: "#fd7e14" // Orange vif pour annulation en cours
      }
    case "PAYMENT_REQUIRED":
      return {
        color: "#FFFFFF", // Texte blanc pour paiement requis
        backgroundColor: "#ff5722" // Orange vif pour paiement requis
      }
    // Nouveau statut "NOT_PAID"
    case "NOT_PAID":
      return {
        color: "#FFFFFF", // Texte blanc pour non payé
        backgroundColor: "#d9534f" // Rouge vif pour non payé (adapté)
      }
    default:
      return {
        color: "#FFFFFF",
        backgroundColor: "#6c757d" // Gris pour statut inconnu
      }
  }
}

const TagChipForPaymentStatus = ({ status }: { status: string }) => {
  const { color, backgroundColor } =
    getColorAndBackgroundColorForPaymentStatus(status)

  return (
    <Chip
      className="font-semibold text-12"
      label={
        status === "PENDING"
          ? "En attente"
          : status === "PAID"
          ? "Payé"
          : status === "CANCELLED"
          ? "Annulé"
          : status === "REFUNDED"
          ? "Remboursé"
          : status === "FAILED"
          ? "Échoué"
          : status === "PENDING_FOR_VALIDATION"
          ? "En attente de validation"
          : status === "PENDING_FOR_CANCELLATION"
          ? "En attente d'annulation"
          : status === "ACTIVE"
          ? "Actif"
          : status === "PENDING_CANCELLATION"
          ? "Annulation en cours"
          : status === "PAYMENT_REQUIRED"
          ? "Paiement requis"
          : status === "NOT_PAID"
          ? "Non payé"
          : "Inconnu"
      }
      sx={{
        color: (theme) =>
          theme.palette.mode === "light"
            ? darken(color, 0.0)
            : lighten(color, 0.0),
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? lighten(backgroundColor, 0.0)
            : darken(backgroundColor, 0.0)
      }}
      size="small"
    />
  )
}

export default TagChipForPaymentStatus
