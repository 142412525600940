/**
 * Configuration object containing the authentication service API endpoints
 */

const baseURL = process.env.REACT_APP_API_URL
const jwtServiceConfig = {
  signIn: `${baseURL}auth/login`,
  signUp: "api/auth/sign-up",
  accessToken: `${baseURL}auth/login`,
  updateUser: "api/auth/user/update"
}

export default jwtServiceConfig
