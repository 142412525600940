import Button from "@mui/material/Button";
import React, { useState } from "react";
import _ from "@lodash";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useAppSelector } from "app/store";
import { ApiServices } from "src/services/ApiServices";
import { selectUser } from "app/store/user/userSlice";

import NotificationTemplate from "app/theme-layouts/shared-components/notificationPanel/NotificationTemplate";
import NotificationModel from "app/theme-layouts/shared-components/notificationPanel/models/NotificationModel";
import { useSnackbar } from "notistack";
// New Imports
import Box from "@mui/material/Box";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import FusePageSimple from "@fuse/core/FusePageSimple";
// Types
interface ProfileEditFormTypes {
  password: string;
  confirmPassword: string;
}

interface ProfileEditTypes {}

/**
 * The contact form.
 */
const ResetPassword: React.FC<ProfileEditTypes> = ({}) => {
  const ProfileUpdateMethod = useForm<ProfileEditFormTypes>({
    mode: "onChange",
  });

  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    control,
    formState: { errors },
    register,
    watch,
    handleSubmit,
    setValue,
  } = ProfileUpdateMethod;

  const { token, email } = useAppSelector(selectUser);

  const [err, setErr] = useState<string>("");

  const onSubmit = (data) => {
    console.log(data);
    ApiServices.updateAdminPassword(token, email, data.password)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setValue("password", "");
          setValue("confirmPassword", "");

          enqueueSnackbar(res.data.data, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else {
          setErr(res.data.error);

          enqueueSnackbar(res.data.error, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);

        enqueueSnackbar(err.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });
  };

  return (
    <FusePageSimple
      header={
        <Box
          className="relative overflow-hidden flex shrink-0 items-center justify-center px-16 py-32 md:p-64"
          sx={{
            backgroundColor: "primary.main",
            color: (theme: Theme) =>
              theme.palette.getContrastText(theme.palette.primary.main),
          }}
        >
          <div className="flex flex-col items-center justify-center  mx-auto w-full">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0 } }}
            >
              <Typography color="inherit" className="text-18 font-semibold">
                Cours PlusPlus
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0 } }}
            >
              <Typography
                color="inherit"
                className="text-center text-32 sm:text-48 font-extrabold tracking-tight mt-4"
              >
                Réinitialiser le mot de passe
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.3 } }}
            >
              <Typography
                color="inherit"
                className="text-16 sm:text-20 mt-16 sm:mt-24 opacity-75 tracking-tight max-w-md text-center"
              >
                Pensez à utiliser un mot de passe sécurisé qui contient au moins
                6 caractères et qui est composé de lettres, de chiffres et de
                symboles.
              </Typography>
            </motion.div>
          </div>

          <svg
            className="absolute inset-0 pointer-events-none"
            viewBox="0 0 960 540"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMax slice"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              className="text-gray-700 opacity-25"
              fill="none"
              stroke="currentColor"
              strokeWidth="100"
            >
              <circle r="234" cx="196" cy="23" />
              <circle r="234" cx="790" cy="491" />
            </g>
          </svg>
        </Box>
      }
      content={
        <div className="relative flex flex-col flex-auto items-center px-24 sm:px-48">
          <div className="w-full max-w-3xl">
            {/* Password */}
            <Controller
              control={control}
              name="password"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  className="mt-32"
                  {...field}
                  label="Mot de passe"
                  placeholder="Mot de passe"
                  id="password"
                  type="password"
                  {...register("password", {
                    min: 6,
                  })}
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            {/* Confirm Password */}
            <Controller
              control={control}
              name="confirmPassword"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  className="mt-32"
                  {...field}
                  label="Confirmer le mot de passe"
                  placeholder="Confirmer le mot de passe"
                  id="confirmPassword"
                  type="password"
                  {...register("confirmPassword", {
                    min: 6,
                    validate: (val: string) => {
                      if (watch("password") != val) {
                        return "Your passwords do no match";
                      }
                    },
                  })}
                  error={!!errors.confirmPassword}
                  helperText={errors?.confirmPassword?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            {err && <span className="text-red-500 text-sm">{err}</span>}
            <Box
              className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t"
              sx={{ backgroundColor: "background.default" }}
            >
              <Button className="ml-auto">Annuler</Button>
              <Button
                className="ml-8"
                variant="contained"
                color="secondary"
                onClick={handleSubmit(onSubmit)}
              >
                Réinitialiser le mot de passe
              </Button>
            </Box>
          </div>
        </div>
      }
      scroll={isMobile ? "normal" : "page"}
    />
  );
};

export default ResetPassword;
