import React, { useEffect } from "react"
import { useState } from "react"
import { motion } from "framer-motion"
import CountCardDropdown from "app/shared-components/CountCardDropdown"
import CountCard from "app/shared-components/CountCard"
import Cookies from "js-cookie"
import { ApiServices } from "src/services/ApiServices"
import { AdminStatistics } from "../../types"
import MonthlyReveneueChart from "app/shared-components/MonthlyRevenueChart"
import { Skeleton } from "@mui/material"
import CirclePercentageChart from "app/shared-components/CirclePercntageChart"
import WaveChart from "app/shared-components/WaveChart"
import { selectUser } from "app/store/user/userSlice"
import { useAppSelector } from "app/store"

const Home = () => {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1
      }
    }
  }
  const { token } = useAppSelector(selectUser)

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  }

  const [adminStatistics, setAdminStatistics] =
    useState<AdminStatistics | null>(null)

  useEffect(() => {
    ApiServices.getAdminStatistics(token)
      .then((res) => {
        setAdminStatistics(res.data.data)
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      <motion.div
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-24 w-full min-w-0 p-24"
        variants={container}
        initial="hidden"
        animate="show"
      >
        {/* Cards */}
        {!adminStatistics ? (
          <Skeleton variant="rectangular" className="rounded-8 h-[220px]" />
        ) : (
          <motion.div variants={item}>
            <CountCard
              title="Total Revenue"
              mainText={
                adminStatistics?.totalRevenue
                  ? String(adminStatistics?.totalRevenue + "$")
                  : `0$`
              }
              subText="Total Revenue"
              footerText="footer Text"
              footerCount="7"
              textColor="text-blue-600"
              darkTextColor="dark:text-blue-500"
            />
          </motion.div>
        )}
        {/* Card */}
        {!adminStatistics ? (
          <Skeleton variant="rectangular" className="rounded-8 h-[220px]" />
        ) : (
          <motion.div variants={item}>
            {" "}
            <CountCard
              title="Professeurs"
              mainText={
                adminStatistics?.usersCountByType["TEACHER"]
                  ? String(adminStatistics?.usersCountByType["TEACHER"])
                  : 0
              }
              subText="Teachers"
              footerCount="90"
              footerText="Dernière mise à jour"
              darkTextColor="dark:text-red-200"
              textColor="text-red-500"
            />
          </motion.div>
        )}
        {/* Card */}
        {!adminStatistics ? (
          <Skeleton variant="rectangular" className="rounded-8 h-[220px]" />
        ) : (
          <motion.div variants={item}>
            <CountCard
              title="Etudiants"
              mainText={
                adminStatistics?.usersCountByType.STUDENT
                  ? String(adminStatistics?.usersCountByType.STUDENT)
                  : 0
              }
              subText="Etudiants"
              footerCount="à l'instant"
              footerText="Dernière mise à jour"
              darkTextColor="dark:text-amber-600"
              textColor="text-amber-600"
            />
          </motion.div>
        )}

        {/* Card */}
        {!adminStatistics ? (
          <Skeleton variant="rectangular" className="rounded-8 h-[220px]" />
        ) : (
          <motion.div variants={item}>
            <CountCard
              title="Test"
              mainText={
                adminStatistics?.totalCourses
                  ? String(adminStatistics?.totalCourses)
                  : 0
              }
              subText="test"
              footerCount="à l'instant"
              footerText="Dernière mise à jour"
              darkTextColor="dark:text-green-500"
              textColor="text-green-500"
            />
          </motion.div>
        )}
      </motion.div>
      {/*  */}

      <motion.div
        variants={item}
        className="sm:col-span-2 lg:col-span-3 pl-24 pr-24 pb-24"
      >
        {!adminStatistics ? (
          <Skeleton
            variant="rectangular"
            className="rounded-8 w-full h-[300px]"
          />
        ) : (
          <WaveChart
            registrationsByRole={
              adminStatistics?.userRegistrationsPerMonthByRole
            }
          />
        )}
      </motion.div>

      {/*  */}

      <div className="sm:col-span-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-32 w-full pl-24 pr-24">
        <motion.div variants={item}>
          {!adminStatistics ? (
            <Skeleton variant="rectangular" className="rounded-8 h-[390px]" />
          ) : (
            <CirclePercentageChart
              cardTitle="Utilisateurs"
              cardLabel="Tous"
              label1="Etudiants"
              label2="Professeurs"
              percentage1={adminStatistics.percentStudents}
              percentage2={adminStatistics.percentTeachers}
              counts={
                adminStatistics.usersCountByType.STUDENT +
                adminStatistics.usersCountByType.TEACHER
              }
              colors={["#3182CE", "#63B3ED"]}
            />
          )}
        </motion.div>
        {/*  */}
        <motion.div variants={item}>
          {!adminStatistics ? (
            <Skeleton variant="rectangular" className="rounded-8 h-[390px]" />
          ) : (
            <CirclePercentageChart
              cardTitle="Sexe"
              cardLabel="All"
              label1="Homme"
              label2="Femme"
              percentage1={adminStatistics.percentMale}
              percentage2={adminStatistics.percentFemale}
              counts={adminStatistics.maleUsers + adminStatistics.femaleUsers}
              colors={["#319795", "#4FD1C5"]}
            />
          )}
        </motion.div>
        {/*  */}
        <motion.div variants={item}>
          {!adminStatistics ? (
            <Skeleton variant="rectangular" className="rounded-8 h-[390px]" />
          ) : (
            <CirclePercentageChart
              cardTitle="Age"
              cardLabel="All"
              label1="<18 ans"
              label2=">18 ans"
              percentage1={
                (adminStatistics.usersUnder18 /
                  (adminStatistics.usersUnder18 +
                    adminStatistics.usersOver18)) *
                100
              }
              percentage2={
                (adminStatistics.usersOver18 /
                  (adminStatistics.usersUnder18 +
                    adminStatistics.usersOver18)) *
                100
              }
              counts={
                adminStatistics.usersUnder18 + adminStatistics.usersOver18
              }
              colors={["#DD6B20", "#F6AD55"]}
            />
          )}
        </motion.div>
        <motion.div variants={item}>
          <CirclePercentageChart
            cardTitle="Language"
            cardLabel="All"
            label1="English"
            label2="Others"
            percentage1={25}
            percentage2={75}
            counts={11521 + 3456375}
            colors={["#805AD5", "#B794F4"]}
          />
        </motion.div>
      </div>
    </>
  )
}

export default Home
