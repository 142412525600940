import FusePageCarded from "@fuse/core/FusePageCarded"
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery"
import { useState } from "react"
import PaymentsAdminHeader from "./PaymentsAdminHeader"
import PaymentsAdminTable from "./PaymentsAdminTable"

/**
 * The Payment page.
 */
function PaymentsAdmin() {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"))
  const [searchText, setSearchText] = useState<string>("")
  const [filterDate, setFilterDate] = useState<Date | null>(null)

  return (
    <FusePageCarded
      header={
        <PaymentsAdminHeader
          searchInputHandle={(text: string) => setSearchText(text)}
          dateFilterHandle={(date: Date) => setFilterDate(date)}
        />
      }
      content={
        <PaymentsAdminTable searchText={searchText} filterDate={filterDate} />
      }
      scroll={isMobile ? "normal" : "content"}
    />
  )
}

export default PaymentsAdmin
