import { useEffect } from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import CountCard from "app/shared-components/CountCard";
import { ApiServices } from "src/services/ApiServices";
import { StudentStatisticsTypes } from "../../types";
import { Skeleton } from "@mui/material";
import { selectUser } from "app/store/user/userSlice";
import { useAppSelector } from "app/store";

const Home = () => {
  const { token, id } = useAppSelector(selectUser);
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  const [StudentStatistics, setStudentStatistics] =
    useState<StudentStatisticsTypes | null>(null);
  const [totalDue, setTotalDue] = useState<
    number | null | undefined | string
  >();

  useEffect(() => {
    ApiServices.getStudentStatistics(token, Number(id))
      .then((res) => {
        setStudentStatistics(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (StudentStatistics) {
      const firstKey = Object.keys(StudentStatistics.monthlyDueAmount)[0];
      const firstValue = StudentStatistics.monthlyDueAmount[firstKey];
      setTotalDue(firstValue);
    }
  }, [StudentStatistics]);

  return (
    <>
      <motion.div
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-24 w-full min-w-0 p-24"
        variants={container}
        initial="hidden"
        animate="show"
      >
        {/* Cards */}
        {!StudentStatistics ? (
          <Skeleton variant="rectangular" className="rounded-8 h-[220px]" />
        ) : (
          <motion.div variants={item}>
            <CountCard
              title="Paiment"
              mainText={totalDue ? totalDue : "0$"}
              subText="Prochain paiment"
              footerText="Prochain paiment"
              footerCount="12/05/2024"
              textColor="text-blue-600"
              darkTextColor="dark:text-blue-500"
            />
          </motion.div>
        )}
        {/* Card */}
        {!StudentStatistics ? (
          <Skeleton variant="rectangular" className="rounded-8 h-[220px]" />
        ) : (
          <motion.div variants={item}>
            {" "}
            <CountCard
              title="Mes cours"
              mainText={
                StudentStatistics?.totalCoursesFollowed
                  ? String(StudentStatistics?.totalCoursesFollowed)
                  : "0"
              }
              subText="Cours suivis"
              footerCount="à l'instant"
              footerText="Dernière mise à jour"
              darkTextColor="dark:text-red-200"
              textColor="text-red-500"
            />
          </motion.div>
        )}
        {/* Card */}
        {!StudentStatistics ? (
          <Skeleton variant="rectangular" className="rounded-8 h-[220px]" />
        ) : (
          <motion.div variants={item}>
            <CountCard
              title="Sessions passées"
              mainText={
                StudentStatistics?.completedSessionsDuration
                  ? String(StudentStatistics?.completedSessionsDuration)
                  : "0"
              }
              subText="Sessions"
              footerCount="à l'instant"
              footerText="Dernière mise à jour"
              darkTextColor="dark:text-amber-600"
              textColor="text-amber-600"
            />
          </motion.div>
        )}

        {/* Card */}
        {!StudentStatistics ? (
          <Skeleton variant="rectangular" className="rounded-8 h-[220px]" />
        ) : (
          <motion.div variants={item}>
            <CountCard
              title="test"
              mainText="200"
              subText="test"
              footerCount="à l'instant"
              footerText="Dernière mise à jour"
              darkTextColor="dark:text-green-500"
              textColor="text-green-500"
            />
          </motion.div>
        )}
      </motion.div>
    </>
  );
};

export default Home;
