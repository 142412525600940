import { Chip } from "@mui/material"
import { darken, lighten } from "@mui/system"
import { SubscriptionTypes } from "./component-types"
const getColorAndBackgroundColor = (type: string) => {
  switch (type) {
    case "PLATINUM":
      return {
        color: "#FFFFFF", // White text for platinum
        backgroundColor: "#232121" // Platinum color
      }
    case "SILVER":
      return {
        color: "#FFFFFF", // Black text for silver
        backgroundColor: "#A8A9AD" // Silver color
      }
    case "GOLD":
      return {
        color: "#FFFFFF", // Black text for gold
        backgroundColor: "#FFD700" // Gold color
      }
    default:
      return {
        color: "#ffffff",
        backgroundColor: "#DAA511"
      }
  }
}

const TagChip = ({ category }: { category: SubscriptionTypes }) => {
  const { color, backgroundColor } = getColorAndBackgroundColor(
    category?.type || ""
  )

  return (
    <Chip
      className="font-semibold text-12"
      label={category?.name}
      sx={{
        color: (theme) =>
          theme.palette.mode === "light"
            ? darken(color, 0.0)
            : lighten(color, 0.0),
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? lighten(backgroundColor, 0.0)
            : darken(backgroundColor, 0.0)
      }}
      size="small"
    />
  )
}

export default TagChip
