import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { Link } from "react-router-dom"
import { InferType } from "yup"
import * as yup from "yup"
import _ from "@lodash"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import AvatarGroup from "@mui/material/AvatarGroup"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import { useEffect, useState } from "react"
import { SignInResponse, UserTypes } from "app/store/user"
import jwtService from "../../auth/services/jwtService"
import { useAppDispatch } from "app/store"
import { setUser } from "app/store/user/userSlice"
import Cookies from "js-cookie"
import { ApiServices } from "src/services/ApiServices"
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
  password: yup
    .string()
    .required("Please enter your password.")
    .min(4, "Password is too short - must be at least 4 chars."),
  remember: yup.boolean()
})

const defaultValues = {
  email: "",
  password: "",
  remember: true
}

/**
 * The sign in page.
 */
function SignInPage() {
  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema)
  })
  const [handleError, setHandleError] = useState<string>("")

  const { isValid, dirtyFields, errors } = formState

  useEffect(() => {
    setValue("email", "votre@email.com", {
      shouldDirty: true,
      shouldValidate: true
    })
    setValue("password", "123456", { shouldDirty: true, shouldValidate: true })
  }, [setValue])

  function onSubmit({ email, password }: InferType<typeof schema>) {
    jwtService
      .signInWithEmailAndPassword(email.toLocaleLowerCase(), password)
      .then((user: SignInResponse) => {
        // eslint-disable-next-line no-console
        // console.info(user);
        // No need to do anything, user data will be set at app/auth/AuthContext
      })
      .catch((_errors: any) => {
        setError(_errors.type, {
          type: "manual",
          message: _errors.message
        })
        setHandleError(_errors.details)
      })
  }

  return (
    <div className="flex min-w-0 flex-1 flex-col items-center sm:flex-row sm:justify-center md:items-start md:justify-start">
      <Paper className="h-full w-full px-16 py-8 ltr:border-r-1 rtl:border-l-1 sm:h-auto sm:w-auto sm:rounded-2xl sm:p-48 sm:shadow md:flex md:h-full md:w-1/2 md:items-center md:justify-end md:rounded-none md:p-64 md:shadow-none">
        <div className="mx-auto w-full max-w-320 sm:mx-0 sm:w-320">
          <img className="w-96" src="assets/images/logo/logo.svg" alt="logo" />

          <Typography className="mt-32 text-4xl font-extrabold leading-tight tracking-tight">
            Se connecter
          </Typography>
          <div className="mt-2 flex items-baseline font-medium">
            <Typography>Vous n'avez pas de compte ?</Typography>
            <Link
              className="ml-4"
              to="https://dev.coursplusplus.com/singup_position"
            >
              S'inscrire
            </Link>
          </div>

          <form
            name="loginForm"
            noValidate
            className="mt-32 flex w-full flex-col justify-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Email"
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Password"
                  type="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <div className="flex flex-col items-center justify-center sm:flex-row sm:justify-between">
              <Controller
                name="remember"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormControlLabel
                      label="Se souvenir de moi"
                      control={<Checkbox size="small" {...field} />}
                    />
                  </FormControl>
                )}
              />

              {/* <Link
                className="text-md font-medium"
                to="/pages/auth/forgot-password"
              >
                Forgot password?
              </Link> */}
            </div>

            {!isValid && <span className="text-red-500">{handleError}</span>}

            <Button
              variant="contained"
              color="secondary"
              className=" mt-16 w-full"
              aria-label="Sign in"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="large"
            >
              Se connecter
            </Button>

            <div className="mt-32 flex items-center">
              <div className="mt-px flex-auto border-t" />
              <Typography className="mx-8" color="text.secondary">
                Ou connectez-vous avec
              </Typography>
              <div className="mt-px flex-auto border-t" />
            </div>

            <div className="mt-32 flex items-center space-x-16">
              <Button variant="outlined" className="flex-auto">
                <FuseSvgIcon size={20} color="action">
                  feather:facebook
                </FuseSvgIcon>
              </Button>
              <Button variant="outlined" className="flex-auto">
                <FuseSvgIcon size={20} color="action">
                  feather:twitter
                </FuseSvgIcon>
              </Button>
              <Button variant="outlined" className="flex-auto">
                <FuseSvgIcon size={20} color="action">
                  feather:github
                </FuseSvgIcon>
              </Button>
            </div>
          </form>
        </div>
      </Paper>

      <Box
        className="relative hidden h-full flex-auto items-center justify-center overflow-hidden p-64 md:flex lg:px-112"
        sx={{ backgroundColor: "primary.main" }}
      >
        <svg
          className="pointer-events-none absolute inset-0"
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Box
            component="g"
            sx={{ color: "primary.light" }}
            className="opacity-20"
            fill="none"
            stroke="currentColor"
            strokeWidth="100"
          >
            <circle r="234" cx="196" cy="23" />
            <circle r="234" cx="790" cy="491" />
          </Box>
        </svg>
        <Box
          component="svg"
          className="absolute -right-64 -top-64 opacity-20"
          sx={{ color: "primary.light" }}
          viewBox="0 0 220 192"
          width="220px"
          height="192px"
          fill="none"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="4" height="4" fill="currentColor" />
            </pattern>
          </defs>
          <rect
            width="220"
            height="192"
            fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
          />
        </Box>

        <div className="relative z-10 w-full max-w-2xl">
          <div className="text-7xl font-bold leading-none text-gray-100">
            <div>Bienvenue dans </div>
            <div>notre communauté 👩🏻‍💻📓✍🏻 </div>
          </div>
          <div className="mt-24 text-lg leading-6 tracking-tight text-gray-400">
            Découvrez <strong> Cours PlusPlus</strong>, un espace où enseignants
            et étudiants façonnent ensemble l'avenir de l'éducation. Lancez-vous
            et commencez à bâtir votre expérience dès aujourd'hui 🚀 !
          </div>
          <div className="mt-32 flex items-center">
            <AvatarGroup
              sx={{
                "& .MuiAvatar-root": {
                  borderColor: "primary.main"
                }
              }}
            >
              <Avatar src="assets/images/avatars/female-18.jpg" />
              <Avatar src="assets/images/avatars/female-11.jpg" />
              <Avatar src="assets/images/avatars/male-09.jpg" />
              <Avatar src="assets/images/avatars/male-16.jpg" />
            </AvatarGroup>

            <div className="ml-16 font-medium tracking-tight text-gray-400">
              Plus de 17k personnes nous ont rejoints, à vous de jouer !
            </div>
          </div>
        </div>
      </Box>
    </div>
  )
}

export default SignInPage
