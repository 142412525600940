import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller, useFormContext, useForm } from "react-hook-form";
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import Box from "@mui/material/Box";
import { lighten, styled } from "@mui/material/styles";
import FuseUtils from "@fuse/utils";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { useEffect, useState } from "react";
import { ApiServices } from "src/services/ApiServices";
//
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * The basic info tab.
 */
function CourseTab() {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext();

  const [allStudyLevels, setAllStudyLevels] = useState([]);
  const [allSubject, setAllSubjects] = useState([]);
  const [allSepecialities, setAllSepecialities] = useState([]);

  useEffect(() => {
    // All Study Level
    ApiServices.getAllStudyLevels()
      .then((res) => setAllStudyLevels(res.data.data))
      .catch((err) => console.log(err));
    // All Subjects
    ApiServices.getAllSubjects()
      .then((res) => setAllSubjects(res.data.data))
      .catch((err) => console.log(err));
    // All Specialities
    ApiServices.getAllSepecialities()
      .then((res) => setAllSepecialities(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  const [image, setImage] = useState<string | null>(null);

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setImage(objectUrl);
    }
  };

  return (
    <div>
      {/* Title */}
      <Controller
        name="title"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            className="mt-8 mb-16"
            required
            label="Titre"
            autoFocus
            id="title"
            variant="outlined"
            fullWidth
            {...register("title", {
              required: {
                value: true,
                message: "Title is required",
              },
              minLength: {
                value: 5,
                message: "Le titre doit comporter au moins 5 caractères",
              },
            })}
            error={!!errors.title}
            helperText={errors?.title?.message as string}
          />
        )}
      />

      {/* Description */}
      <Controller
        name="description"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <TextField
            {...field}
            className="mt-8 mb-16"
            required
            label="Description"
            id="description"
            variant="outlined"
            fullWidth
            multiline
            {...register("description", {
              required: {
                value: true,
                message: "La description est requise",
              },
              minLength: {
                value: 10,
                message: "La description doit comporter au moins 10 caractères",
              },
            })}
            minRows={4}
            maxRows={10}
            error={!!errors.description}
            helperText={errors?.description?.message as string}
          />
        )}
      />
      {/* content */}
      <Controller
        name="content"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <TextField
            {...field}
            className="mt-8 mb-16"
            required
            label="Contenu"
            id="content"
            variant="outlined"
            fullWidth
            multiline
            {...register("content", {
              required: {
                value: true,
                message: "Le contenu est requis",
              },
              minLength: {
                value: 10,
                message: "Le contenu doit comporter au moins 10 caractères",
              },
            })}
            minRows={4}
            maxRows={10}
            error={!!errors.content}
            helperText={errors?.content?.message as string}
          />
        )}
      />
      {/* std level */}
      <Controller
        name="studyLevelId"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <FormControl
            fullWidth
            variant="outlined"
            className="flex items-center"
          >
            <InputLabel id="study-level-label">Niveau d'étude</InputLabel>
            <Select
              {...field}
              value={field.value}
              className="mt-8 mb-16"
              onChange={(e) => field.onChange(e.target.value)}
              fullWidth
              variant="outlined"
              labelId="study-level-label"
              label="Study Level"
            >
              {allStudyLevels?.map((level) => (
                <MenuItem key={level.id} value={level.id}>
                  {level.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      {/* Subject */}
      <Controller
        name="subjectId"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <FormControl fullWidth variant="outlined">
            <InputLabel id="subject-label">Matière</InputLabel>
            <Select
              {...field}
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              fullWidth
              className="mt-8 mb-16"
              variant="outlined"
              labelId="subject-label"
              label="Matière"
              {...register("subjectId", {
                required: {
                  value: true,
                  message: "La matière est requise",
                },
              })}
            >
              {allSubject?.map((level) => (
                <MenuItem key={level.id} value={level.id}>
                  {level.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      {/* specialty Ids */}
      {/* <Controller
        name="specialtyIds"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <FormControl fullWidth variant="outlined">
            <InputLabel id="Specialties-label">Filière</InputLabel>
            <Select
              {...field}
              multiple={true}
              value={field.value || []}
              onChange={(e) => field.onChange(e.target.value)}
              fullWidth
              className="mt-8 mb-16"
              variant="outlined"
              labelId="Specialties-label"
              label="Specialties"
              {...register("specialtyIds", {
                required: {
                  value: true,
                  message: "La filière est requise",
                },
              })}
            >
              {allSepecialities?.map((level) => (
                <MenuItem key={level.id} value={level.id}>
                  {level.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      /> */}
      {/*  */}
      <FormControl fullWidth variant="outlined" error={!!errors.specialtyIds}>
        <Controller
          name="specialtyIds"
          control={control}
          render={({ field }) => {
            // Ensure field.value is defined and is an array
            const selectedIds = Array.isArray(field.value) ? field.value : [];

            return (
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={allSepecialities || []}
                disableCloseOnSelect
                className="mt-8 mb-16"
                getOptionLabel={(option) => option.name || ""}
                value={
                  allSepecialities.filter((option) =>
                    selectedIds.includes(option.id)
                  ) || []
                }
                onChange={(event, newValue) => {
                  // Map the selected objects to their IDs
                  const idsArray = newValue.map((option) => option.id);
                  field.onChange(idsArray);
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filière"
                    placeholder="Specialties"
                    error={!!errors.specialtyIds}
                  />
                )}
              />
            );
          }}
        />
        {errors.specialtyIds && (
          <FormHelperText>Specialties is required</FormHelperText>
        )}
      </FormControl>
      {/*  */}

      <input
        name="updatedPhoto"
        id="updatedPhoto"
        type="file"
        className="hidden"
      />

      {/*  */}
      <Controller
        name="photos"
        control={control}
        render={({ field }) => (
          // <label htmlFor="course_image">
          <>
            <p className="ml-6 my-4 mt-8">
              Cliquez sur l'image pour la modifier
            </p>

            <Box
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? lighten(theme.palette.background.default, 0.4)
                    : lighten(theme.palette.background.default, 0.02),
              }}
              component="label"
              htmlFor="button-file"
              className="productImageUpload flex items-center justify-center relative w-128 h-128 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
              onClick={() => document.getElementById("course_image").click()}
            >
              {field.value ? (
                typeof field.value === "string" ? (
                  <img src={field.value} alt="Course Photo" />
                ) : (
                  <img
                    src={URL.createObjectURL(field.value)}
                    alt="Course Photo"
                  />
                )
              ) : (
                <FuseSvgIcon size={32} color="action">
                  heroicons-outline:upload
                </FuseSvgIcon>
              )}
              <input
                name="photos"
                id="course_image"
                type="file"
                className="w-1 h-1 "
                onChange={(e) => {
                  field.onChange(e.target.files[0]);
                }}
              />
            </Box>
          </>
          // </label>
        )}
      />
      {errors.photos && (
        <span className="text-red-500 text-sm">Please Select Course Image</span>
      )}

      {/*  */}
    </div>
  );
}

export default CourseTab;
