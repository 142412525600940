import _ from "@lodash"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { motion } from "framer-motion"
import { ChangeEvent, useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Switch from "@mui/material/Switch"
import { FormControlLabel } from "@mui/material"
import FusePageSimple from "@fuse/core/FusePageSimple"
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery"
import { useAppSelector } from "app/store"
import { Theme } from "@mui/material/styles"
import GroupCard from "./GroupsCard"
import CourseType from "../types/GroupType"
import { ApiServices } from "src/services/ApiServices"
import { selectUser } from "app/store/user/userSlice"

const container = {
  show: {
    transition: {
      staggerChildren: 0.1
    }
  }
}

const item = {
  hidden: {
    opacity: 0,
    y: 20
  },
  show: {
    opacity: 1,
    y: 0
  }
}

/**
 * The Courses page.
 */
function GroupsList() {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"))

  const [filteredData, setFilteredData] = useState<CourseType[]>([])
  const [searchText, setSearchText] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("all")
  const [hideCompleted, setHideCompleted] = useState(false)

  const { token, id } = useAppSelector(selectUser)

  useEffect(() => {
    ApiServices.getAllGroupsByTecherId(Number(id))
      .then((res) => {
        setFilteredData(res.data.data)
      })
      .catch((err) => console.log(err))
  }, [])

  function handleSelectedCategory(event: SelectChangeEvent<string>) {
    setSelectedCategory(event.target.value)
  }

  function handleSearchText(event: ChangeEvent<HTMLInputElement>) {
    setSearchText(event.target.value)
  }
  return (
    <FusePageSimple
      header={
        <Box
          className="relative overflow-hidden flex shrink-0 items-center justify-center px-16 py-32 md:p-64"
          sx={{
            backgroundColor: "primary.main",
            color: (theme: Theme) =>
              theme.palette.getContrastText(theme.palette.primary.main)
          }}
        >
          <div className="flex flex-col items-center justify-center  mx-auto w-full">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0 } }}
            >
              <Typography color="inherit" className="text-18 font-semibold">
                Cours PlusPlus
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0 } }}
            >
              <Typography
                color="inherit"
                className="text-center text-32 sm:text-48 font-extrabold tracking-tight mt-4"
              >
                La liste de mes étudiants 🧑‍🎓
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.3 } }}
            >
              <Typography
                color="inherit"
                className="text-16 sm:text-20 mt-16 sm:mt-24 opacity-75 tracking-tight max-w-md text-center"
              >
                Les étudiants sont triés par groupe. Pour voir la liste de vos
                étudiants, veuillez d'abord sélectionner un groupe 👥.
              </Typography>
            </motion.div>
          </div>

          <svg
            className="absolute inset-0 pointer-events-none"
            viewBox="0 0 960 540"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMax slice"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              className="text-gray-700 opacity-25"
              fill="none"
              stroke="currentColor"
              strokeWidth="100"
            >
              <circle r="234" cx="196" cy="23" />
              <circle r="234" cx="790" cy="491" />
            </g>
          </svg>
        </Box>
      }
      content={
        <div className="flex flex-col flex-1 w-full mx-auto px-24 pt-24 sm:p-40">
          <div className="flex flex-col shrink-0 sm:flex-row items-center justify-between space-y-16 sm:space-y-0">
            <div className="flex flex-col sm:flex-row w-full sm:w-auto items-center space-y-16 sm:space-y-0 sm:space-x-16">
              <FormControl className="flex w-full sm:w-136" variant="outlined">
                <InputLabel id="category-select-label">Abonnement</InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  label="Abonnement"
                  value={selectedCategory}
                  onChange={handleSelectedCategory}
                >
                  <MenuItem value="all">
                    <em> Tous </em>
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Chercher un cours"
                placeholder="Entrez un cours"
                className="flex w-full sm:w-256 mx-8"
                value={searchText}
                inputProps={{
                  "aria-label": "Search"
                }}
                onChange={handleSearchText}
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </div>
          </div>
          {filteredData &&
            (filteredData.length > 0 ? (
              <motion.div
                className="flex grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-32 mt-32 sm:mt-40"
                variants={container}
                initial="hidden"
                animate="show"
              >
                {filteredData
                  .filter((group) =>
                    group.name.toLowerCase().includes(searchText.toLowerCase())
                  )
                  .map((group) => {
                    return (
                      <motion.div variants={item} key={group.id}>
                        <GroupCard course={group} />
                      </motion.div>
                    )
                  })}
              </motion.div>
            ) : (
              <div className="flex flex-1 items-center justify-center">
                <Typography color="text.secondary" className="text-24 my-24">
                  Vous n'avez pas encore d'étudiants pourle moment 🧑‍🎓.
                </Typography>
              </div>
            ))}
        </div>
      }
      scroll={isMobile ? "normal" : "page"}
    />
  )
}

export default GroupsList
