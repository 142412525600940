import { useAppSelector } from "app/store";
import { selectUserRole } from "app/store/user/userSlice";
// Profiles
import AdminProfile from "./AdminProfile/Profile";
import TeacherProfile from "./TeacherProfile/Profile";
import StudentProfile from "./StudentProfile/Profile";

const ProfileLayout = () => {
  const userRole = useAppSelector(selectUserRole);

  const renderProfileRole = () => {
    switch (userRole) {
      case "ADMIN":
        return <AdminProfile />;
      case "TEACHER":
        return <TeacherProfile />;
      case "STUDENT":
        return <StudentProfile />;
      default:
        return <div>Error: Invalid User Role</div>;
    }
  };

  return <>{renderProfileRole()}</>;
};

export default ProfileLayout;
