import FuseScrollbars from "@fuse/core/FuseScrollbars"
import _ from "@lodash"
import Checkbox from "@mui/material/Checkbox"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { motion } from "framer-motion"
import { ChangeEvent, MouseEvent, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "app/store"
import FuseLoading from "@fuse/core/FuseLoading"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import * as React from "react"
import ProductsTableHead from "./PaymentsTableHead"
import { ApiServices } from "src/services/ApiServices"
import { useNavigate } from "react-router-dom"
import { PaymentTypes } from "./types/PaymentTypes"
import TagChip from "app/shared-components/TagChip"
import { selectUser } from "app/store/user/userSlice"
import TagChipForPaymentStatus from "app/shared-components/TagChipForPaymentStatus"

interface SubjectTableTypes {
  searchText: string
  filterDate: Date | null
}

/**
 * The products table.
 */

const getPaymentMethodInFrench = (method: string) => {
  switch (method) {
    case "STRIPE":
      return "Carte bancaire 💳"
    case "WIRE_TRANSFER":
      return "Virement bancaire 💸"
    default:
      return "Inconnu"
  }
}

function PaymentsTable(props: SubjectTableTypes) {
  const { searchText, filterDate } = props
  // const navigate = useNavigate();

  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState<string[]>([])
  const [data, setData] = useState<PaymentTypes[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [tableOrder, setTableOrder] = useState<{
    direction: "asc" | "desc"
    id: string
  }>({
    direction: "asc",
    id: ""
  })

  const filteredData = data
    .filter((payment) =>
      payment.studentName.toLowerCase().includes(searchText.toLowerCase())
    )
    .filter((payment) => {
      if (filterDate) {
        const paymentDate = new Date(payment.paymentDate)
        return (
          paymentDate.getMonth() === filterDate.getMonth() &&
          paymentDate.getFullYear() === filterDate.getFullYear()
        )
      }
      return true
    })
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  function handleRequestSort(
    event: MouseEvent<HTMLSpanElement>,
    property: string
  ) {
    const newOrder: {
      direction: "asc" | "desc"
      id: string
    } = { id: property, direction: "desc" }

    if (tableOrder.id === property && tableOrder.direction === "desc") {
      newOrder.direction = "asc"
    }

    setTableOrder(newOrder)
  }

  function handleSelectAllClick(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      setSelected(data.map((n) => n.stripeSubscriptionId))
      return
    }

    setSelected([])
  }

  function handleDeselect() {
    setSelected([])
  }

  function handleClick(id: string) {
    // navigate(`/subject/${id}`);
  }

  function handleCheck(event: ChangeEvent<HTMLInputElement>, id: string) {
    const selectedIndex = selected.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) {
    setPage(+page)
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value)
  }

  const { token, id } = useAppSelector(selectUser)

  const LoadPageData = () => {
    ApiServices.getPaymentsByTeacherId(token, id).then((res) => {
      setData(res.data.data)

      setLoading(false)
    })
  }

  useEffect(() => {
    LoadPageData()
  }, [])

  const handleDeleteItem = () => {
    ApiServices.removeSubjectById(token, String(selected))
      .then((res) => {
        console.log(res)
        LoadPageData()
        handleDeselect()
      })
      .catch((err) => console.log(err))
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <FuseLoading />
      </div>
    )
  }

  if (data.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-1 items-center justify-center h-full"
      >
        <Typography color="text.secondary" variant="h5">
          Aucun payement disponible pour le moment 💸.
        </Typography>
      </motion.div>
    )
  }

  return (
    <>
      <div className="w-full flex flex-col min-h-full">
        <FuseScrollbars className="grow overflow-x-auto">
          <Table stickyHeader className="min-w-xl" aria-labelledby="tableTitle">
            <ProductsTableHead
              selectedProductIds={selected}
              tableOrder={tableOrder}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredData.length}
              onMenuItemClick={handleDeselect}
              handleDeleteItem={handleDeleteItem}
            />
            <TableBody>
              {_.orderBy(
                filteredData,
                ["studentName"],
                [tableOrder.direction as "asc" | "desc"]
              ).map((n, index) => {
                const isSelected =
                  selected.indexOf(n.enrollmentId.toString()) !== -1

                return (
                  <TableRow
                    className="h-72 cursor-pointer"
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.stripeSessionId || n.enrollmentId}
                    selected={isSelected}
                    onClick={() => handleClick(n.stripeSubscriptionId)}
                  >
                    <TableCell
                      className="w-40 md:w-64 text-center"
                      padding="none"
                    >
                      <Checkbox
                        checked={isSelected}
                        onClick={(event) => event.stopPropagation()}
                        onChange={(event) =>
                          handleCheck(event, n.stripeSubscriptionId)
                        }
                      />
                    </TableCell>
                    <TableCell
                      className="w-52 px-4 md:px-0"
                      component="th"
                      scope="row"
                      padding="none"
                    >
                      <img
                        className="w-full block rounded"
                        src="assets/images/apps/ecommerce/product-image-placeholder.png"
                        alt={"payment screen"}
                      />
                    </TableCell>
                    <TableCell
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      {n.studentName}
                    </TableCell>
                    <TableCell
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      {n.courseTitle}
                    </TableCell>
                    <TableCell
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <TagChip
                        category={{
                          name: n.subscriptionType,
                          type: n.subscriptionType
                        }}
                      />
                    </TableCell>
                    <TableCell
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      {n.groupName}
                    </TableCell>
                    <TableCell
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      {new Date(n.paymentDate).toLocaleDateString()}{" "}
                      {new Date(n.paymentDate).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit"
                      })}
                    </TableCell>
                    <TableCell
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      {n.price} DH
                    </TableCell>
                    <TableCell
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      {getPaymentMethodInFrench(n.paymentMethod)}
                    </TableCell>
                    <TableCell
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <TagChipForPaymentStatus status={n.enrollmentStatus} />
                    </TableCell>
                    <TableCell
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <TagChipForPaymentStatus status={n.paymentStatus} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </FuseScrollbars>
        <TablePagination
          className="shrink-0 border-t-1"
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  )
}

export default PaymentsTable
