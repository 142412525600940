import Paper from "@mui/material/Paper"
import { lighten, useTheme } from "@mui/material/styles"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import Box from "@mui/material/Box"

/**
 * The GithubIssuesWidget widget.
 */

interface MonthlyRevenue {
  [month: string]: number
}

interface MonthlyData {
  [month: string]: number
}

interface MonthlyReveneueChartTypes {
  title: string
  title1: string
  title2: string
  revenueData?: {
    monthlyRevenue: MonthlyRevenue
    monthlyRevenueSilver: MonthlyRevenue
    monthlyRevenueGold: MonthlyRevenue
    monthlyRevenuePlatinum: MonthlyRevenue
    activeStudentsByMonthSilver: MonthlyData
    activeStudentsByMonthGold: MonthlyData
    activeStudentsByMonthPlatinum: MonthlyData
    unsubscribedStudentsByMonthSilver: MonthlyData
    unsubscribedStudentsByMonthGold: MonthlyData
    unsubscribedStudentsByMonthPlatinum: MonthlyData
  }
  totalRevenueGenerated: number
  totalRevenueGeneratedSilver: number
  totalRevenueGeneratedGold: number
  totalRevenueGeneratedPlatinum: number
}

const MonthlyReveneueChart: React.FC<MonthlyReveneueChartTypes> = ({
  title,
  title1,
  title2,
  revenueData,
  totalRevenueGenerated,
  totalRevenueGeneratedSilver,
  totalRevenueGeneratedGold,
  totalRevenueGeneratedPlatinum
}) => {
  const theme = useTheme()
  const [awaitRender, setAwaitRender] = useState(true)
  const [tabValue, setTabValue] = useState(0)

  // Function to get monthly revenue based on the selected tab
  const getMonthlyRevenue = () => {
    switch (tabValue) {
      case 0:
        return revenueData.monthlyRevenue
      case 1:
        return revenueData.monthlyRevenueSilver
      case 2:
        return revenueData.monthlyRevenueGold
      case 3:
        return revenueData.monthlyRevenuePlatinum
      default:
        return {}
    }
  }

  const setMonthlyRevenue = () => {
    switch (tabValue) {
      case 0:
        return totalRevenueGenerated
      case 1:
        return totalRevenueGeneratedSilver
      case 2:
        return totalRevenueGeneratedGold
      case 3:
        return totalRevenueGeneratedPlatinum
      default:
        return {}
    }
  }

  const monthlyRevenue = getMonthlyRevenue()
  const TabTotalRevenue = setMonthlyRevenue()

  // Convert the API response to the format expected by ApexCharts
  const chartData = Object.keys(monthlyRevenue).map((month) => ({
    x: month, // Use the month as the x value
    y: monthlyRevenue[month] // Use the revenue as the y value
  }))

  const getActiveStudents = () => {
    switch (tabValue) {
      case 0:
        return (
          Object.values(revenueData.activeStudentsByMonthSilver).reduce(
            (a, b) => a + b,
            0
          ) +
          Object.values(revenueData.activeStudentsByMonthGold).reduce(
            (a, b) => a + b,
            0
          ) +
          Object.values(revenueData.activeStudentsByMonthPlatinum).reduce(
            (a, b) => a + b,
            0
          )
        )
      case 1:
        return Object.values(revenueData.activeStudentsByMonthSilver).reduce(
          (a, b) => a + b,
          0
        )
      case 2:
        return Object.values(revenueData.activeStudentsByMonthGold).reduce(
          (a, b) => a + b,
          0
        )
      case 3:
        return Object.values(revenueData.activeStudentsByMonthPlatinum).reduce(
          (a, b) => a + b,
          0
        )
      default:
        return 0
    }
  }

  const getUnsubscribedStudents = () => {
    switch (tabValue) {
      case 0:
        return (
          Object.values(revenueData.unsubscribedStudentsByMonthSilver).reduce(
            (a, b) => a + b,
            0
          ) +
          Object.values(revenueData.unsubscribedStudentsByMonthGold).reduce(
            (a, b) => a + b,
            0
          ) +
          Object.values(revenueData.unsubscribedStudentsByMonthPlatinum).reduce(
            (a, b) => a + b,
            0
          )
        )
      case 1:
        return Object.values(
          revenueData.unsubscribedStudentsByMonthSilver
        ).reduce((a, b) => a + b, 0)
      case 2:
        return Object.values(
          revenueData.unsubscribedStudentsByMonthGold
        ).reduce((a, b) => a + b, 0)
      case 3:
        return Object.values(
          revenueData.unsubscribedStudentsByMonthPlatinum
        ).reduce((a, b) => a + b, 0)
      default:
        return 0
    }
  }

  useEffect(() => {
    setAwaitRender(false)
  }, [])

  if (awaitRender) {
    return null
  }

  const months = Object.keys(monthlyRevenue)

  return (
    <Paper className="flex flex-col flex-auto p-24 shadow rounded-2xl overflow-hidden">
      <div className="flex flex-col sm:flex-row items-start justify-between">
        <Typography className="text-lg font-medium tracking-tight leading-6 truncate">
          {title}
        </Typography>
        <div className="mt-12 sm:mt-0 sm:ml-8">
          <Tabs
            value={tabValue}
            onChange={(ev, value: number) => setTabValue(value)}
            indicatorColor="secondary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons={false}
            className="-mx-4 min-h-40"
            classes={{
              indicator: "flex justify-center bg-transparent w-full h-full"
            }}
            TabIndicatorProps={{
              children: (
                <Box
                  sx={{ bgcolor: "text.disabled" }}
                  className="w-full h-full rounded-full opacity-20"
                />
              )
            }}
          >
            <Tab
              className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
              disableRipple
              label={"Tous les abonnements"}
            />
            <Tab
              className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
              disableRipple
              label={"Silver"}
            />
            <Tab
              className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
              disableRipple
              label={"Gold"}
            />
            <Tab
              className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
              disableRipple
              label={"Platinum"}
            />
          </Tabs>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 grid-flow-row gap-24 w-full mt-32 sm:mt-16">
        <div className="flex flex-col flex-auto">
          <Typography className="font-medium" color="text.secondary">
            {title1}
          </Typography>
          <div className="flex flex-col flex-auto">
            <ReactApexChart
              className="flex-auto w-full"
              options={{
                colors: ["#4f46e5"],
                chart: {
                  fontFamily: "inherit",
                  foreColor: "inherit",
                  height: "100%",
                  toolbar: {
                    show: false
                  },
                  zoom: {
                    enabled: false
                  }
                },
                dataLabels: {
                  enabled: true,
                  enabledOnSeries: [0],
                  background: {
                    borderWidth: 0
                  }
                },
                grid: {
                  borderColor: theme.palette.divider
                },
                legend: {
                  show: false
                },
                plotOptions: {
                  bar: {
                    columnWidth: "50%"
                  }
                },
                states: {
                  hover: {
                    filter: {
                      type: "darken",
                      value: 0.75
                    }
                  }
                },
                tooltip: {
                  followCursor: true,
                  theme: theme.palette.mode
                },
                xaxis: {
                  type: "category",
                  categories: chartData.map((dataPoint) => dataPoint.x)
                },
                yaxis: {
                  labels: {
                    offsetX: -16,
                    style: {
                      colors: theme.palette.text.secondary
                    }
                  }
                }
              }}
              series={[{ data: chartData }]}
              height={320}
              type="bar"
            />
          </div>
        </div>
        <div className="flex flex-col">
          <Typography className="font-medium" color="text.secondary">
            {title2}
          </Typography>
          <div className="flex-auto grid grid-cols-4 gap-16 mt-24">
            <div className="col-span-4 flex flex-col items-center justify-center py-32 px-4 rounded-2xl bg-green-50 text-green-800">
              <Typography className="text-5xl sm:text-7xl font-semibold leading-none tracking-tight">
                {String(TabTotalRevenue)} Dhs
              </Typography>
              <Typography className="mt-4 text-sm sm:text-lg font-medium">
                Total Revenue
              </Typography>
            </div>
            <Box
              sx={{
                backgroundColor: (_theme) =>
                  _theme.palette.mode === "light"
                    ? lighten(theme.palette.background.default, 0.4)
                    : lighten(theme.palette.background.default, 0.02)
              }}
              className="col-span-2 sm:col-span-2 flex flex-col items-center justify-center py-32 px-4 rounded-2xl"
            >
              <Typography className="text-5xl font-semibold leading-none tracking-tight">
                {getActiveStudents()}
              </Typography>
              <Typography className="mt-4 text-sm font-medium text-center">
                Etudiants actifs 😊
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: (_theme) =>
                  _theme.palette.mode === "light"
                    ? lighten(theme.palette.background.default, 0.4)
                    : lighten(theme.palette.background.default, 0.02)
              }}
              className="col-span-2 sm:col-span-1 flex flex-col items-center justify-center py-32 px-4 rounded-2xl"
            >
              <Typography className="text-5xl font-semibold leading-none tracking-tight">
                {getUnsubscribedStudents()}
              </Typography>
              <Typography className="mt-4 text-sm font-medium text-center">
                Etudiants désinscrits 😕
              </Typography>
            </Box>
          </div>
        </div>
      </div>
    </Paper>
  )
}

export default MonthlyReveneueChart
