import React, { useEffect, useState } from "react";
import ChangePassword from "./ChangePassword";
import ChangeProfile from "./ChangeProfile";
import { useForm } from "react-hook-form";
import { AdminProfileTypes } from "./types";

interface ProfileEditTypes {
  handleEdit: () => void;
  userData: AdminProfileTypes;
  loadData: () => void;
}

/**
 * The contact form.
 */
const ProfileEdit: React.FC<ProfileEditTypes> = ({
  handleEdit,
  userData,
  loadData,
}) => {
  return (
    <ChangeProfile
      handleEdit={handleEdit}
      userData={userData}
      loadData={loadData}
    />
  );
};

export default ProfileEdit;
