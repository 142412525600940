import Button from "@mui/material/Button"
import Input from "@mui/material/Input"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { motion } from "framer-motion"
import { ChangeEvent, useEffect, useState } from "react"
import { DatePicker } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import frLocale from "date-fns/locale/fr"
import { TextField, Box } from "@mui/material"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"

interface PaymentHeaderTeacherTypes {
  searchInputHandle: (text: string) => void
  dateFilterHandle: (date: Date) => void
}

function PaymentsAdminTeacherHeader(props: PaymentHeaderTeacherTypes) {
  const { searchInputHandle, dateFilterHandle } = props
  const [searchText, setSearchText] = useState<string>("")
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)

  useEffect(() => {
    searchInputHandle(searchText)
  }, [searchText])

  useEffect(() => {
    if (selectedDate) {
      dateFilterHandle(selectedDate)
    }
  }, [selectedDate])

  return (
    <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32">
      <motion.span
        initial={{ x: -20 }}
        animate={{ x: 0, transition: { delay: 0.2 } }}
      >
        <Typography className="text-24 md:text-32 font-extrabold tracking-tight">
          Statut des paiements des professeurs
        </Typography>
      </motion.span>
      <div className="flex flex-col w-full sm:w-auto sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center justify-end space-x-8">
        <Paper
          component={motion.div}
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
          className="flex items-center w-full sm:max-w-256 space-x-8 px-16 rounded-full border-1 shadow-0"
        >
          <FuseSvgIcon color="disabled">heroicons-solid:search</FuseSvgIcon>

          <Input
            placeholder="Trouver un professeur..."
            className="flex flex-1"
            disableUnderline
            fullWidth
            value={searchText}
            inputProps={{
              "aria-label": "Search"
            }}
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setSearchText(ev.target.value)
            }
          />
        </Paper>
        <Box className="fflex items-center w-full sm:max-w-256 space-x-8 px-16 rounded-full">
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={frLocale} // Utilisation de `adapterLocale` pour définir la localisation
          >
            <DatePicker
              views={["year", "month"]}
              label={"Filtrer par date"}
              minDate={new Date("2020-01-01")}
              maxDate={new Date()}
              value={selectedDate}
              onChange={(newValue) => setSelectedDate(newValue)}
              slots={{ textField: TextField }}
              slotProps={{
                textField: {
                  variant: "standard"
                }
              }}
            />
          </LocalizationProvider>
        </Box>
      </div>
    </div>
  )
}

export default PaymentsAdminTeacherHeader
