import FusePageCarded from "@fuse/core/FusePageCarded";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import SubjectsHeader from "./SubjectsHeader";
import SubjectsTable from "./SubjectsTable";
import { useState } from "react";

/**
 * The products page.
 */
function Subjects() {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));

  const [searchText, setSearchText] = useState<string>("");

  return (
    <FusePageCarded
      header={
        <SubjectsHeader
          searchInputHandle={(text: string) => setSearchText(text)}
        />
      }
      content={<SubjectsTable searchText={searchText} />}
      scroll={isMobile ? "normal" : "content"}
    />
  );
}

export default Subjects;
