import React from "react";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { memo, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";

interface CountCardTypes {
  title?: string;
  mainText?: string | number;
  subText?: string;
  footerText?: string;
  footerCount?: string;
  textColor?: string;
  darkTextColor?: string;
}

const CountCard: React.FC<CountCardTypes> = ({
  title,
  mainText,
  subText,
  footerText,
  footerCount,
  textColor,
  darkTextColor,
}) => {
  return (
    <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
      <div className="flex items-center justify-between px-8 pt-12">
        <Typography
          className="px-16 py-8 text-lg font-medium tracking-tight leading-6 truncate"
          color="text.secondary"
        >
          {title}
        </Typography>
        {/* <IconButton aria-label="more" size="large">
          <FuseSvgIcon>heroicons-outline:dots-vertical</FuseSvgIcon>
        </IconButton> */}
      </div>
      <div className="text-center mt-8">
        <Typography
          className={`text-7xl sm:text-8xl font-bold tracking-tight leading-none ${textColor}`}
        >
          {mainText}
        </Typography>
        <Typography
          className={`text-lg font-medium ${textColor}  ${darkTextColor}`}
        >
          {subText}
        </Typography>
      </div>
      <Typography
        className="flex items-baseline justify-center w-full mt-20 mb-24"
        color="text.secondary"
      >
        <span className="truncate">{footerText}</span>:
        <b className="px-8">{footerCount}</b>
      </Typography>
    </Paper>
  );
};

export default CountCard;
