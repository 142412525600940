import FuseScrollbars from "@fuse/core/FuseScrollbars";
import _ from "@lodash";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/store";
import FuseLoading from "@fuse/core/FuseLoading";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import * as React from "react";
import ProductsTableHead from "./UsersTableHead";
import { UserTypes } from "./types/users";
import { ApiServices } from "src/services/ApiServices";
import { Link, useNavigate } from "react-router-dom";
import { selectUser } from "app/store/user/userSlice";
import Box from "@mui/material/Box";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import NotificationModel from "app/theme-layouts/shared-components/notificationPanel/models/NotificationModel";
import NotificationTemplate from "app/theme-layouts/shared-components/notificationPanel/NotificationTemplate";

interface UsersTableTypes {
  searchText: string;
}

/**
 * The products table.
 */
function UsersTable(props: UsersTableTypes) {
  const { token } = useAppSelector(selectUser);

  const { searchText } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<number[]>([]);
  const [data, setData] = useState<UserTypes[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableOrder, setTableOrder] = useState<{
    direction: "asc" | "desc";
    id: string;
  }>({
    direction: "asc",
    id: "",
  });

  const [anchorElArr, setAnchorElArr] = useState(Array(data.length).fill(null));

  const handleMenuOpen = (event, index) => {
    const newAnchorElArr = [...anchorElArr];
    newAnchorElArr[index] = event.currentTarget;
    setAnchorElArr(newAnchorElArr);
  };

  const handleMenuClose = () => {
    setAnchorElArr(Array(data.length).fill(null));
  };

  function handleRequestSort(
    event: MouseEvent<HTMLSpanElement>,
    property: string
  ) {
    const newOrder: {
      direction: "asc" | "desc";
      id: string;
    } = { id: property, direction: "desc" };

    if (tableOrder.id === property && tableOrder.direction === "desc") {
      newOrder.direction = "asc";
    }

    setTableOrder(newOrder);
  }

  function handleSelectAllClick(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      setSelected(data.map((n) => n.id));
      return;
    }

    setSelected([]);
  }

  function handleDeselect() {
    setSelected([]);
  }

  function handleClick(id: number) {
    navigate(`/users/${id}`);
  }

  function handleCheck(event: ChangeEvent<HTMLInputElement>, id: number) {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) {
    setPage(+page);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value);
  }

  const LoadPageData = () => {
    ApiServices.getAllUsers(token).then((res) => {
      setData(res.data.data);

      setLoading(false);
    });
  };

  useEffect(() => {
    LoadPageData();
  }, []);

  const handleDeleteItem = () => {
    ApiServices.removeUserById(token, String(selected))
      .then((res) => {
        LoadPageData();
        handleDeselect();
      })
      .catch((err) => console.log(err));
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <FuseLoading />
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-1 items-center justify-center h-full"
      >
        <Typography color="text.secondary" variant="h5">
          Vous n'avez pas encore d'étudiants pourle moment 🧑‍🎓.
        </Typography>
      </motion.div>
    );
  }

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleActiveStatus = (id: number) => {
    ApiServices.handleUserStatus(token, id)
      .then((res) => {
        LoadPageData();

        enqueueSnackbar(res.data.data, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      })
      .catch((err) => {
        console.log(err);

        enqueueSnackbar(err.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });
  };

  const handleDeactiveStatus = (id: number) => {
    ApiServices.handleUserStatusDeactive(token, id)
      .then((res) => {
        LoadPageData();
        enqueueSnackbar(res.data.data, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });
  };

  return (
    <>
      {/* Table Here */}

      <div className="w-full flex flex-col min-h-full">
        <FuseScrollbars className="grow overflow-x-auto">
          <Table stickyHeader className="min-w-xl" aria-labelledby="tableTitle">
            <ProductsTableHead
              selectedProductIds={selected}
              tableOrder={tableOrder}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              handleDeleteItem={handleDeleteItem}
            />

            <TableBody>
              {_.orderBy(
                data,
                ["firstName"],
                [tableOrder.direction as "asc" | "desc"]
              )
                .filter(
                  (user) =>
                    user?.firstName
                      ?.toLowerCase()
                      .includes(searchText.toLowerCase())
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, index) => {
                  const isSelected = selected.indexOf(n.id) !== -1;
                  return (
                    <TableRow
                      className="h-72 cursor-pointer"
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                      onClick={() => handleClick(n.id)}
                    >
                      <TableCell
                        className="w-40 md:w-64 text-center"
                        padding="none"
                      >
                        <Checkbox
                          checked={isSelected}
                          onClick={(event) => event.stopPropagation()}
                          onChange={(event) => handleCheck(event, n.id)}
                        />
                      </TableCell>

                      <TableCell
                        className="w-52 px-4 md:px-0"
                        component="th"
                        scope="row"
                        padding="none"
                      >
                        {n?.photos?.length > 0 ? (
                          <img
                            className="w-full block rounded"
                            src={
                              "https://cpp-photo-bucket.s3.eu-north-1.amazonaws.com/" +
                              n.photos[0].s3Url
                            }
                            alt={n.firstName}
                          />
                        ) : (
                          <img
                            className="w-full block rounded"
                            src="assets/images/apps/ecommerce/product-image-placeholder.png"
                            alt={n.firstName}
                          />
                        )}
                      </TableCell>
                      {/* Name */}
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                      >
                        {n.firstName + " " + n.lastName}
                      </TableCell>
                      {/* Role */}
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                      >
                        {n.role}
                      </TableCell>
                      {/* gender */}
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                      >
                        {n.gender}
                      </TableCell>
                      {/* Status */}
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                      >
                        {n.active ? (
                          <FuseSvgIcon className="text-green" size={20}>
                            heroicons-outline:check-circle
                          </FuseSvgIcon>
                        ) : (
                          <FuseSvgIcon className="text-red" size={20}>
                            heroicons-outline:minus-circle
                          </FuseSvgIcon>
                        )}
                      </TableCell>
                      {/* Action */}
                      <TableCell
                        className="p-4"
                        component="th"
                        scope="row"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Box className="flex items-center rounded-full justify-center">
                          <IconButton
                            aria-controls={`simple-menu-${index}`}
                            aria-haspopup="true"
                            onClick={(e) => handleMenuOpen(e, index)}
                            size="large"
                            className="hover:bg-transparent"
                          >
                            <FuseSvgIcon>
                              heroicons-outline:dots-horizontal
                            </FuseSvgIcon>
                          </IconButton>
                          <Menu
                            id={`simple-menu-${index}`}
                            anchorEl={anchorElArr[index]}
                            keepMounted
                            open={Boolean(anchorElArr[index])}
                            onClose={handleMenuClose}
                          >
                            {n.active ? (
                              <MenuItem
                                onClick={() => {
                                  handleMenuClose();
                                  handleDeactiveStatus(n.id);
                                }}
                              >
                                Disbale
                              </MenuItem>
                            ) : (
                              <MenuItem
                                onClick={() => {
                                  handleMenuClose();
                                  handleActiveStatus(n.id);
                                }}
                              >
                                Enable
                              </MenuItem>
                            )}
                          </Menu>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </FuseScrollbars>

        <TablePagination
          className="shrink-0 border-t-1"
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}

export default UsersTable;
