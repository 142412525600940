import React, { useEffect, useState } from "react";
import ProfileEdit from "./ProfileEdit";
import ProfileView from "./ProfileView";
import { ApiServices } from "src/services/ApiServices";
import { StudentProfileTypes } from "./types";
import { useAppSelector } from "app/store";
import { selectUser } from "app/store/user/userSlice";

const Profile = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [student, setStudent] = useState<StudentProfileTypes | null>();

  const { token, id } = useAppSelector(selectUser);

  const loadData = () => {
    setStudent(null);
    ApiServices.getStudentDetailsById(id)
      .then((res) => {
        setStudent(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadData();
  }, []);
  return (
    <>
      {!isEdit ? (
        <ProfileView
          handleEdit={() => setIsEdit(!isEdit)}
          userData={student}
          loadData={loadData}
        />
      ) : (
        <ProfileEdit
          handleEdit={() => setIsEdit(!isEdit)}
          userData={student}
          loadData={loadData}
        />
      )}
    </>
  );
};

export default Profile;
