import _ from "@lodash"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { motion } from "framer-motion"
import { ChangeEvent, useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Switch from "@mui/material/Switch"
import { FormControlLabel } from "@mui/material"
import FusePageSimple from "@fuse/core/FusePageSimple"
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery"
import { useAppSelector } from "app/store"
import { Theme } from "@mui/material/styles"
import GroupCard from "./GroupsCard"
import CourseType from "./GroupType"
import { ApiServices } from "src/services/ApiServices"
import { selectUser } from "app/store/user/userSlice"

const container = {
  show: {
    transition: {
      staggerChildren: 0.1
    }
  }
}

const item = {
  hidden: {
    opacity: 0,
    y: 20
  },
  show: {
    opacity: 1,
    y: 0
  }
}

const Groups = () => {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"))

  const [filteredData, setFilteredData] = useState<CourseType[]>([])
  const [searchText, setSearchText] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("all")
  const [hideCompleted, setHideCompleted] = useState(false)

  const { token, id } = useAppSelector(selectUser)

  useEffect(() => {
    ApiServices.getAllGroupsByTecherId(Number(id))
      .then((res) => {
        setFilteredData(res.data.data)
      })
      .catch((err) => console.log(err))
  }, [])

  function handleSelectedCategory(event: SelectChangeEvent<string>) {
    setSelectedCategory(event.target.value)
  }

  function handleSearchText(event: ChangeEvent<HTMLInputElement>) {
    setSearchText(event.target.value)
  }
  return (
    <div>
      <div className="flex flex-col flex-1 w-full mx-auto px-24 pt-24 sm:p-40">
        <div className="flex flex-col shrink-0 sm:flex-row items-center justify-between space-y-16 sm:space-y-0">
          <div className="flex flex-col sm:flex-row w-full sm:w-auto items-center space-y-16 sm:space-y-0 sm:space-x-16">
            <FormControl className="flex w-full sm:w-136" variant="outlined">
              <InputLabel id="category-select-label">Abonnement</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                label="Category"
                value={selectedCategory}
                onChange={handleSelectedCategory}
              >
                <MenuItem value="all">
                  <em> Tous </em>
                </MenuItem>
                <MenuItem value="demo">
                  <em>Silver</em>
                </MenuItem>
                <MenuItem value="demo">
                  <em>Gold</em>
                </MenuItem>
                <MenuItem value="demo">
                  <em>Platinum</em>
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Rechercher un groupe"
              placeholder="Nom du groupe..."
              className="flex w-full sm:w-256 mx-8"
              value={searchText}
              inputProps={{
                "aria-label": "Search"
              }}
              onChange={handleSearchText}
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
        </div>
        {filteredData &&
          (filteredData.length > 0 ? (
            <motion.div
              className="flex grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-32 mt-32 sm:mt-40"
              variants={container}
              initial="hidden"
              animate="show"
            >
              {filteredData
                .filter((group) =>
                  group.name.toLowerCase().includes(searchText.toLowerCase())
                )
                .map((group) => {
                  return (
                    <motion.div variants={item} key={group.id}>
                      <GroupCard course={group} />
                    </motion.div>
                  )
                })}
            </motion.div>
          ) : (
            <div className="flex flex-1 items-center justify-center">
              <Typography color="text.secondary" className="text-24 my-24">
                Vous n'avez pas encore d'étudiants pourle moment 🧑‍🎓.
              </Typography>
            </div>
          ))}
      </div>
    </div>
  )
}

export default Groups
