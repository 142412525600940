import React, { useEffect, useState } from "react";
import ProfileEdit from "./ProfileEdit";
import ProfileView from "./ProfileView";
import { useAppSelector } from "app/store";
import { selectUser } from "app/store/user/userSlice";
import { TeacherProfileTypes } from "./types";
import { ApiServices } from "src/services/ApiServices";

const Profile = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { id } = useAppSelector(selectUser);

  const [teacher, setTeacher] = useState<TeacherProfileTypes | null>();

  const loadData = () => {
    setTeacher(null);
    ApiServices.getTeacherDetailsById(id)
      .then((res) => {
        setTeacher(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadData();
  }, []);
  return (
    <>
      {!isEdit ? (
        <ProfileView handleEdit={() => setIsEdit(!isEdit)} userData={teacher} />
      ) : (
        <ProfileEdit
          loadData={loadData}
          handleEdit={() => setIsEdit(!isEdit)}
          userData={teacher}
        />
      )}
    </>
  );
};

export default Profile;
