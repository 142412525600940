import { styled } from "@mui/material/styles"

const Root = styled("div")(({ theme }) => ({
  "& > .logo-icon": {
    transition: theme.transitions.create(["width", "height"], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    })
  },
  "& > .badge": {
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    })
  }
}))

/**
 * The logo component.
 */
function Logo() {
  return (
    <Root className="flex items-center">
      <img
        className="logo-icon h-32 w-32"
        src="assets/images/logo/logo-light.svg"
        alt="logo"
      />
      <div
        className="badge mx-8 flex items-center rounded px-8 py-4"
        style={{ backgroundColor: "#121212", color: "#FFFFF" }}
      >
        <span className="react-text mx-4 text-12">Dashboard</span>
      </div>
    </Root>
  )
}

export default Logo
