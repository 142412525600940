import FusePageCarded from "@fuse/core/FusePageCarded"
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery"
import { useState } from "react"
import RefundHeader from "./RefundHeader"
import PaymentsAdminTable from "./RefundTable"

/**
 * The Payment page.
 */
function Refund() {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"))

  const [searchText, setSearchText] = useState<string>("")

  return (
    <FusePageCarded
      header={
        <RefundHeader
          searchInputHandle={(text: string) => setSearchText(text)}
        />
      }
      content={<PaymentsAdminTable searchText={searchText} />}
      scroll={isMobile ? "normal" : "content"}
    />
  )
}

export default Refund
