import FusePageCarded from "@fuse/core/FusePageCarded";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import SpecilatiesHeader from "./SpecilatiesHeader";
import SpecilatiesTable from "./SpecilatiesTable";
import { useState } from "react";

/**
 * The products page.
 */
function Specilaties() {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [searchText, setSearchText] = useState<string>("");
  return (
    <FusePageCarded
      header={
        <SpecilatiesHeader
          searchInputHandle={(text: string) => setSearchText(text)}
        />
      }
      content={<SpecilatiesTable searchText={searchText} />}
      scroll={isMobile ? "normal" : "content"}
    />
  );
}

export default Specilaties;
