import * as React from "react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import { showMessage } from "app/store/fuse/messageSlice";
import { logoutUser, setUser } from "app/store/user/userSlice";
import { useAppDispatch } from "app/store";
import { AxiosError } from "axios";
import { UserTypes } from "app/store/user";
import jwtService from "./services/jwtService";
import Cookies from "js-cookie";

/**
 * The AuthContext object is a React context object that provides authentication information to child components.
 */
const AuthContext = React.createContext({});

type AuthProviderProps = { children: ReactNode };

/**
 * The AuthProvider component is a wrapper component that provides authentication information to child components.
 */
function AuthProvider(props: AuthProviderProps) {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useAppDispatch();
  const val = useMemo(() => ({ isAuthenticated }), [isAuthenticated]);

  useEffect(() => {
    const userData = Cookies.get("user_session");

    if (userData) {
      success(JSON.parse(userData), "");
    } else {
      jwtService.on("onAutoLogin", () => {
        dispatch(showMessage({ message: "Signing in with JWT" }));

        /**
         * Sign in and retrieve user data with stored token
         */
        jwtService
          .signInWithToken()
          .then((user) => {
            success(user as UserTypes, "Signed in with JWT");
          })
          .catch((error: AxiosError) => {
            pass(error.message);
          });
      });
      jwtService.on("onLogin", (user: UserTypes) => {
        success(user, "Signed in");

        const TWELVE_HOURS_IN_MS = 12 * 60 * 60 * 1000;
        const EXPIRATION_TIME_IN_DAYS =
          TWELVE_HOURS_IN_MS / (1000 * 60 * 60 * 24);

        Cookies.set("user_session", JSON.stringify(user), {
          expires: EXPIRATION_TIME_IN_DAYS,
        });
      });

      jwtService.on("onNoAccessToken", () => {
        pass();
      });
    }

    jwtService.on("onLogout", () => {
      pass("Signed out");
      Cookies.remove("user_session");
      setTimeout(() => {
        window.location.href = "/";
      }, 3000);
      // dispatch(logoutUser());
    });

    jwtService.on("onAutoLogout", (message: string) => {
      pass(message);
      dispatch(logoutUser());
    });

    jwtService.init();

    function success(user: UserTypes, message: string) {
      Promise.all([
        dispatch(setUser(user)),

        // You can receive data in here before app initialization
      ]).then(() => {
        if (message) {
          dispatch(showMessage({ message }));
        }

        setWaitAuthCheck(false);
        setIsAuthenticated(true);
      });
    }

    function pass(message?: string) {
      if (message) {
        dispatch(showMessage({ message }));
      }

      setWaitAuthCheck(false);
      setIsAuthenticated(false);
    }
  }, [dispatch]);

  return waitAuthCheck ? (
    <FuseSplashScreen />
  ) : (
    <AuthContext.Provider value={val}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { useAuth, AuthProvider };
