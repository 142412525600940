import _ from "@lodash"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { motion } from "framer-motion"
import { ChangeEvent, useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Switch from "@mui/material/Switch"
import { Avatar, FormControlLabel, Paper } from "@mui/material"
import FusePageSimple from "@fuse/core/FusePageSimple"
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery"
import { useAppSelector } from "app/store"
import { Theme } from "@mui/material/styles"

// import GroupCard from "../groups/GroupsCard";
// import CourseType from "../types/GroupType";
import { ApiServices } from "src/services/ApiServices"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import { useParams } from "react-router-dom"
import { StudentTypes } from "../types/StudentsType"
import { selectUser } from "app/store/user/userSlice"

const container = {
  show: {
    transition: {
      staggerChildren: 0.1
    }
  }
}

const item = {
  hidden: {
    opacity: 0,
    y: 20
  },
  show: {
    opacity: 1,
    y: 0
  }
}

/**
 * The Courses page.
 */
function GroupsList() {
  const { token, id } = useAppSelector(selectUser)
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"))

  const [students, setStudents] = useState<StudentTypes[]>([])
  const [searchText, setSearchText] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("all")
  const [hideCompleted, setHideCompleted] = useState(false)
  const routerParams = useParams()

  useEffect(() => {
    const { groupid } = routerParams

    ApiServices.getStudentListByGroupId(token, Number(groupid))
      .then((res) => {
        setStudents(res.data.data)
      })
      .catch((err) => console.log(err))
  }, [])

  function handleSelectedCategory(event: SelectChangeEvent<string>) {
    setSelectedCategory(event.target.value)
  }

  function handleSearchText(event: ChangeEvent<HTMLInputElement>) {
    setSearchText(event.target.value)
  }
  return (
    <FusePageSimple
      header={
        <Box
          className="relative overflow-hidden flex shrink-0 items-center justify-center px-16 py-32 md:p-64"
          sx={{
            backgroundColor: "primary.main",
            color: (theme: Theme) =>
              theme.palette.getContrastText(theme.palette.primary.main)
          }}
        >
          <div className="flex flex-col items-center justify-center  mx-auto w-full">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0 } }}
            >
              <Typography color="inherit" className="text-18 font-semibold">
                Cours PlusPlus
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0 } }}
            >
              <Typography
                color="inherit"
                className="text-center text-32 sm:text-48 font-extrabold tracking-tight mt-4"
              >
                Liste des étudiants du groupe
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.3 } }}
            >
              <Typography
                color="inherit"
                className="text-16 sm:text-20 mt-16 sm:mt-24 opacity-75 tracking-tight max-w-md text-center"
              >
                Consultez ici les groupes d'étudiants que vous encadrez.
                Engagez-vous avec chacun pour optimiser leur parcours
                d'apprentissage et contribuer à leur succès académique.
              </Typography>
            </motion.div>
          </div>

          <svg
            className="absolute inset-0 pointer-events-none"
            viewBox="0 0 960 540"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMax slice"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              className="text-gray-700 opacity-25"
              fill="none"
              stroke="currentColor"
              strokeWidth="100"
            >
              <circle r="234" cx="196" cy="23" />
              <circle r="234" cx="790" cy="491" />
            </g>
          </svg>
        </Box>
      }
      content={
        <div className="flex flex-col flex-1 w-full mx-auto px-24 pt-24 sm:p-40">
          <div className="flex flex-col shrink-0 sm:flex-row items-center justify-between space-y-16 sm:space-y-0">
            <div className="flex flex-col sm:flex-row w-full sm:w-auto items-center space-y-16 sm:space-y-0 sm:space-x-16">
              <FormControl className="flex w-full sm:w-136" variant="outlined">
                <InputLabel id="category-select-label">Abonnement</InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  label="Abonnement"
                  value={selectedCategory}
                  onChange={handleSelectedCategory}
                >
                  <MenuItem value="Tous">
                    <em> Tous </em>
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Trouver un étudiant"
                placeholder="Nom d'étudiant ..."
                className="flex w-full sm:w-256 mx-8"
                value={searchText}
                inputProps={{
                  "aria-label": "Search"
                }}
                onChange={handleSearchText}
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </div>

            <FormControlLabel
              label="Cacher les étudiants desactivés"
              control={
                <Switch
                  onChange={(ev) => {
                    setHideCompleted(ev.target.checked)
                  }}
                  checked={hideCompleted}
                  name="hideCompleted"
                />
              }
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-24 w-full min-w-0 p-24">
            {students
              ?.filter(
                (student) =>
                  student.firstName
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                  student.lastName
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                  student.email.toLowerCase().includes(searchText.toLowerCase())
              )
              .map((student) => {
                const nameInitial = student.firstName.charAt(0).toUpperCase()
                return (
                  <Paper
                    component={motion.div}
                    variants={item}
                    className="flex flex-col flex-auto items-center shadow rounded-2xl overflow-hidden"
                    key={student.id}
                  >
                    <div className="flex flex-col flex-auto w-full p-32 text-center">
                      <div className="w-128 h-128 mx-auto rounded-full overflow-hidden">
                        {student.photos.length > 0 ? (
                          <img
                            className="w-full h-full object-cover"
                            src={`https://cpp-photo-bucket.s3.eu-north-1.amazonaws.com/${student.photos[0].s3Url}`}
                            alt="student"
                          />
                        ) : (
                          <Avatar className="w-full h-full object-cover">
                            {nameInitial}
                          </Avatar>
                        )}
                      </div>
                      <Typography className="mt-24 font-medium">
                        {student.firstName + " " + student.lastName}
                      </Typography>
                      <Typography color="text.secondary">
                        {student.email}
                      </Typography>
                    </div>
                    <div className="flex items-center w-full border-t divide-x">
                      <a
                        className="flex flex-auto items-center justify-center py-16 hover:bg-hover"
                        href={`mailto:${student.email}`}
                        role="button"
                      >
                        <FuseSvgIcon size={20} color="action">
                          heroicons-solid:mail
                        </FuseSvgIcon>
                        <Typography className="ml-8">Email</Typography>
                      </a>
                      <a
                        className="flex flex-auto items-center justify-center py-16 hover:bg-hover"
                        href={`tel${student.phone}`}
                        role="button"
                      >
                        <FuseSvgIcon size={20} color="action">
                          heroicons-solid:phone
                        </FuseSvgIcon>
                        <Typography className="ml-8">Call</Typography>
                      </a>
                    </div>
                  </Paper>
                )
              })}
          </div>
        </div>
      }
      scroll={isMobile ? "normal" : "page"}
    />
  )
}

export default GroupsList
