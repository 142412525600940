import { useAppSelector } from "app/store";
import { selectUserRole } from "app/store/user/userSlice";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminDashboard from "./dashboardAdmin/AdminDashboard";
import TeacherDashboard from "./dashboardTeacher/TeacherDashboard";
import StudentDashboard from "./dashboardStudent/StudentDashboard";

const DashboardLayout = () => {
  const userRole = useAppSelector(selectUserRole);

  const renderDashboardRole = () => {
    switch (userRole) {
      case "ADMIN":
        return <AdminDashboard />;
      case "TEACHER":
        return <TeacherDashboard />;
      case "STUDENT":
        return <StudentDashboard />;
      default:
        return <div>Error: Invalid User Role</div>;
    }
  };

  return <>{renderDashboardRole()}</>;
};

export default DashboardLayout;
