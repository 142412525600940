import FusePageCarded from "@fuse/core/FusePageCarded"
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery"
import { useState } from "react"
import PaymentsAdminTeacherHeader from "./PaymentsAdminTeacherHeader"
import PaymentsAdminTeacherTable from "./PaymentsAdminTable"

/**
 * The Payment page.
 */
function PaymentsAdminTeacher() {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"))
  const [searchText, setSearchText] = useState<string>("")
  const [filterDate, setFilterDate] = useState<Date | null>(null)

  return (
    <FusePageCarded
      header={
        <PaymentsAdminTeacherHeader
          searchInputHandle={(text: string) => setSearchText(text)}
          dateFilterHandle={(date: Date) => setFilterDate(date)}
        />
      }
      content={
        <PaymentsAdminTeacherTable
          searchText={searchText}
          filterDate={filterDate}
        />
      }
      scroll={isMobile ? "normal" : "content"}
    />
  )
}

export default PaymentsAdminTeacher
