import Button from "@mui/material/Button"
import { useTheme } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import { motion } from "framer-motion"
import { useFormContext } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "app/store"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "@lodash"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"

import { ApiServices } from "src/services/ApiServices"
import { useEffect, useState } from "react"
import Cookies from "js-cookie"
import { selectUser } from "app/store/user/userSlice"

//
function useLocalStorage(key: string, initialValue: boolean | number) {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key)
    return storedValue ? JSON.parse(storedValue) : initialValue
  })

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === key) {
        setValue(JSON.parse(event.newValue))
      }
    }

    window.addEventListener("storage", handleStorageChange)

    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [key])

  const setStoredValue = (newValue) => {
    setValue(newValue)
    localStorage.setItem(key, JSON.stringify(newValue))
  }

  return [value, setStoredValue]
}
/**
 * The product header.
 */
function ProductHeader() {
  const { token, id } = useAppSelector(selectUser)

  const dispatch = useAppDispatch()
  const param = useParams().id
  const methods = useFormContext()
  const routeParams = useParams()
  const { path } = routeParams
  // const { formState, watch, getValues } = methods;
  // const { isValid, dirtyFields } = formState;
  // const [platinumIsSubmit, setPlatinumIsSubmit] = useLocalStorage(
  //   "platinumSubmit",
  //   false
  // );
  // const [silverIsSubmit, setSilverIsSubmit] = useLocalStorage(
  //   "silverSubmit",
  //   false
  // );
  // const [goldIsSubmit, setGoldIsSubmit] = useLocalStorage("goldSubmit", false);

  // const [platinumSubId, setPlatinumSubId] = useLocalStorage("platinumSubId", 0);
  // const [silverSubId, setSilverSubId] = useLocalStorage("silverSubId", 0);
  // const [goldSubId, setGoldSubId] = useLocalStorage("pgoldSubId", 0);
  //
  //
  //
  //

  const theme = useTheme()
  const navigate = useNavigate()

  // const handleFinish = () => {
  //   navigate("/courses");
  //   const { id } = routeParams;
  //   ApiServices.coursePublishStatus(token, Number(id));

  //   localStorage.removeItem("silverSubId");
  //   localStorage.removeItem("platinumSubId");
  //   localStorage.removeItem("goldSubId");
  // };

  return (
    <div className="flex flex-col sm:flex-row flex-1 w-full items-center justify-between space-y-8 sm:space-y-0 py-32 px-24 md:px-32">
      <div className="flex flex-col items-center sm:items-start space-y-8 sm:space-y-0 w-full sm:max-w-full min-w-0">
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
        >
          <Typography
            className="flex items-center sm:mb-12"
            component={Link}
            role="button"
            to="/courses"
            color="inherit"
            onClick={() => {
              const { id } = routeParams
              ApiServices.coursePublishStatus(token, Number(id))

              localStorage.removeItem("silverSubId")
              localStorage.removeItem("platinumSubId")
              localStorage.removeItem("goldSubId")
            }}
          >
            <FuseSvgIcon size={20}>
              {theme.direction === "ltr"
                ? "heroicons-outline:arrow-sm-left"
                : "heroicons-outline:arrow-sm-right"}
            </FuseSvgIcon>
            <span className="flex mx-4 font-medium">Courses</span>
          </Typography>
        </motion.div>

        <div className="flex items-center max-w-full">
          <motion.div
            className="hidden sm:flex"
            initial={{ scale: 0 }}
            animate={{ scale: 1, transition: { delay: 0.3 } }}
          >
            {/* {image && image.length > 0 ? (
              <img className="w-32 sm:w-48 rounded" src={image} alt={name} />
            ) : (
              <img
                className="w-32 sm:w-48 rounded"
                src="assets/images/apps/ecommerce/product-image-placeholder.png"
                alt={name}
              />
            )} */}
          </motion.div>
          <motion.div
            className="flex flex-col items-center sm:items-start min-w-0 mx-8 sm:mx-16"
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.3 } }}
          >
            <Typography className="text-16 sm:text-20 truncate font-semibold">
              {"Modifier le cours"}
            </Typography>
            <Typography variant="caption" className="font-medium">
              Details du cours
            </Typography>
          </motion.div>
        </div>
      </div>
      {/* <motion.div
        className="flex"
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
      >
        <Button
          className="whitespace-nowrap mx-4"
          variant="contained"
          color="secondary"
          onClick={handleFinish}
        >
          Update
        </Button>
      </motion.div> */}
    </div>
  )
}

export default ProductHeader
