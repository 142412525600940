import Button from "@mui/material/Button"
import FuseLoading from "@fuse/core/FuseLoading"
import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"
import Chip from "@mui/material/Chip"
import Divider from "@mui/material/Divider"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import Box from "@mui/system/Box"
import format from "date-fns/format"
import _ from "@lodash"
import { StudentProfileTypes } from "./types"
import exampleCover from "../../../../data/example_cover.jpg"

/**
 * The contact view.
 */

interface ProfileViewTypes {
  handleEdit: () => void
  userData: StudentProfileTypes
  loadData: () => void
}

const ProfileView: React.FC<ProfileViewTypes> = ({ handleEdit, userData }) => {
  if (!userData) {
    return <FuseLoading className="min-h-screen" />
  }

  return (
    <>
      <Box
        className="relative w-full h-160 sm:h-192 px-32 sm:px-48"
        sx={{
          backgroundColor: "background.default"
        }}
      >
        {userData?.backgroundPhoto ? (
          <img
            className="absolute inset-0 object-cover w-full h-full"
            src={userData.backgroundPhoto}
            alt="user background"
          />
        ) : (
          <img
            className="absolute inset-0 object-cover w-full h-full"
            src={exampleCover}
            alt="user background"
          />
        )}
      </Box>
      <div className="relative flex flex-col flex-auto items-center p-24 pt-0 sm:p-48 sm:pt-0">
        <div className="w-full max-w-3xl">
          <div className="flex flex-auto items-end -mt-64">
            <Avatar
              sx={{
                borderWidth: 4,
                borderStyle: "solid",
                borderColor: "background.paper",
                backgroundColor: "background.default",
                color: "text.secondary"
              }}
              className="w-128 h-128 text-64 font-bold"
              src={`https://cpp-photo-bucket.s3.eu-north-1.amazonaws.com/${userData?.photos[0]?.s3Url}`}
              alt={userData?.firstName}
            >
              {userData?.firstName?.charAt(0)}
            </Avatar>
            <div className="flex items-center ml-auto mb-4">
              <Button
                variant="contained"
                color="secondary"
                className=" mt-16 w-full"
                aria-label="Sign in"
                type="submit"
                size="large"
                onClick={handleEdit}
              >
                <FuseSvgIcon size={20} className="mr-4">
                  heroicons-outline:pencil-alt
                </FuseSvgIcon>
                Edit
              </Button>
            </div>
          </div>

          <Typography className="mt-12 text-4xl font-bold truncate">
            {userData?.firstName + " " + userData?.lastName}
          </Typography>

          <div className="flex flex-wrap items-center mt-8">
            <Chip label={userData?.role} className="mr-12 mb-12" size="small" />
          </div>

          <Divider className="mt-16 mb-24" />

          <div className="flex flex-col space-y-32">
            {userData?.aboutMe && (
              <div className="flex items-center">
                <FuseSvgIcon>heroicons-outline:briefcase</FuseSvgIcon>
                <div className="ml-24 leading-6">{userData.aboutMe}</div>
              </div>
            )}

            {userData?.email && (
              <div className="flex">
                <FuseSvgIcon>heroicons-outline:mail</FuseSvgIcon>
                <div className="min-w-0 ml-24 space-y-4">
                  <div
                    className="flex items-center leading-6"
                    key={userData.email}
                  >
                    <a
                      className="hover:underline text-primary-500"
                      href={`mailto: ${userData.email}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {userData.email}
                    </a>
                    {/* {item.label && (
                      <Typography
                        className="text-md truncate"
                        color="text.secondary"
                      >
                        <span className="mx-8">&bull;</span>
                        <span className="font-medium">{item.label}</span>
                      </Typography>
                    )} */}
                  </div>
                </div>
              </div>
            )}

            {userData?.phone && (
              <div className="flex">
                <FuseSvgIcon>heroicons-outline:phone</FuseSvgIcon>
                <div className="min-w-0 ml-24 space-y-4">
                  <div className="flex items-center leading-6">
                    <Box
                      className="hidden sm:flex w-24 h-16 overflow-hidden"
                      sx={{
                        background:
                          "url('/assets/images/apps/contacts/flags.png') no-repeat 0 0",
                        backgroundSize: "24px 3876px"
                      }}
                    />

                    <div className="ml-10 font-mono">{userData.phone}</div>
                  </div>
                </div>
              </div>
            )}

            {userData?.address && (
              <div className="flex items-center">
                <FuseSvgIcon>heroicons-outline:location-marker</FuseSvgIcon>
                <div className="ml-24 leading-6">{userData.address}</div>
              </div>
            )}

            {userData?.birthDate && (
              <div className="flex items-center">
                <FuseSvgIcon>heroicons-outline:cake</FuseSvgIcon>
                <div className="ml-24 leading-6">
                  {format(new Date(userData.birthDate), "MMMM d, y")}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileView
