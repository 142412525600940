import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardActions from "@mui/material/CardActions"
import Button from "@mui/material/Button"
import { Link } from "react-router-dom"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import { lighten } from "@mui/material/styles"
import CourseInfo from "../CourseInfo"
import CourseProgress from "../CourseProgress"
import GroupType from "../types/GroupType"

type CourseCardProps = {
  course: GroupType
}

/**
 * The CourseCard component.
 */
function GroupCard(props: CourseCardProps) {
  const { course } = props

  function buttonStatus() {
    // switch (course.id) {
    // 	case course.totalSteps:
    // 		return 'Completed';
    // 	case 0:
    // 		return 'Start';
    // 	default:
    // 		return 'Continue';
    // }

    return "Etudiants"
  }

  function startMeeting() {
    // switch (course.id) {
    // 	case course.totalSteps:
    // 		return 'Completed';
    // 	case 0:
    // 		return 'Start';
    // 	default:
    // 		return 'Continue';
    // }

    return "Rejoindre"
  }
  return (
    <Card className="flex flex-col h-384 shadow">
      <CardContent className="flex flex-col flex-auto p-24">
        <CourseInfo course={course} />
      </CardContent>
      {/* <CourseProgress course={course} /> */}
      <CardActions
        className="items-center justify-end py-16 px-24"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.03)
        }}
      >
        <Button
          to={`/group_students/${course.id}`}
          component={Link}
          className="px-16 min-w-128"
          color="primary"
          variant="contained"
          endIcon={
            <FuseSvgIcon size={20}>heroicons-solid:user-group</FuseSvgIcon>
          }
        >
          {buttonStatus()}
        </Button>
        <Button
          to={`https://meet.google.com/gvb-kuuy-fka`}
          component={Link}
          className="px-16 min-w-128"
          color="secondary"
          variant="contained"
          endIcon={
            <FuseSvgIcon size={20}>heroicons-solid:video-camera</FuseSvgIcon>
          }
        >
          {startMeeting()}
        </Button>
      </CardActions>
    </Card>
  )
}

export default GroupCard
