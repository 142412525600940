import FuseLoading from "@fuse/core/FuseLoading";
import FusePageCarded from "@fuse/core/FusePageCarded";
import { useDeepCompareEffect } from "@fuse/hooks";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import React, { SyntheticEvent, useState } from "react";
import { useAppSelector } from "app/store";
import { Link, useParams, useNavigate } from "react-router-dom";
import _ from "@lodash";
import { FormProvider, useForm } from "react-hook-form";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";

import ProductHeader from "./FormHeader";
import CrourseTab from "./tabs/CourseTab";
import SubscriptionTab from "./tabs/SubscriptionTab";
import GroupsTab from "./tabs/GroupsTab";
import { ApiServices } from "src/services/ApiServices";

//
import { useSnackbar } from "notistack";
import NotificationTemplate from "app/theme-layouts/shared-components/notificationPanel/NotificationTemplate";
import NotificationModel from "app/theme-layouts/shared-components/notificationPanel/models/NotificationModel";
import { selectUser } from "app/store/user/userSlice";

/**
 * Form Validation Schema
 */

type CourseValues = {
  title: string;
  description: string;
  content: string;
  studyLevelId: number;
  subjectId: number;
  specialtyIds: number[];
  photos: string | File;
  updatedPhoto: File;
};

/**
 * The StudyLevel Form.
 */
function SubscriptionsForm() {
  // const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  // const { data: product, status } = useAppSelector(selectProduct);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const routeParams = useParams();

  const [tabValue, setTabValue] = useState(0);
  const [noProduct, setNoProduct] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const methodsCourse = useForm<CourseValues>({
    mode: "onChange",
  });

  // const { control, formState, register, setValue } = methods;

  useDeepCompareEffect(() => {
    function updateProductState() {
      const { id } = routeParams;
      if (id !== "new") {
        /**
         * Get Study Level By id
         */

        ApiServices.getCourseById(Number(id))
          .then((res) => {
            const {
              id,
              title,
              description,
              content,
              studyLevel,
              subject,
              specialties,
              photos,
            } = res.data.data;

            const studyLevelIdValue = studyLevel ? studyLevel.id : null;
            const subjectIdValue = subject ? subject.id : null;
            const specialtiesIds = specialties
              ? specialties.map(({ id }) => id)
              : null;

            methodsCourse.setValue("title", title);
            methodsCourse.setValue("description", description);
            methodsCourse.setValue("content", content);
            methodsCourse.setValue("studyLevelId", studyLevelIdValue);
            methodsCourse.setValue("subjectId", subjectIdValue);
            methodsCourse.setValue("specialtyIds", specialtiesIds);
            methodsCourse.setValue(
              "photos",
              `https://cpp-photo-bucket.s3.eu-north-1.amazonaws.com/${photos[0].s3Url}`
            );
          })
          .catch((err) => console.log(err));
      }
    }

    updateProductState();
  }, [routeParams]);

  /**
   * Tab Change
   */
  function handleTabChange(event: SyntheticEvent, value: number) {
    setTabValue(value);
  }
  /**
   * Show Message if the requested products is not exists
   */
  if (noProduct) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-col flex-1 items-center justify-center h-full"
      >
        <Typography color="text.secondary" variant="h5">
          There is no such product!
        </Typography>
        <Button
          className="mt-24"
          component={Link}
          variant="outlined"
          to="/apps/e-commerce/products"
          color="inherit"
        >
          Go to Products Page
        </Button>
      </motion.div>
    );
  }

  /**
   * Wait while product data is loading and form is setted
   */
  // if (dataIsLoading) {
  //   return <FuseLoading />;
  // }
  const { token } = useAppSelector(selectUser);

  const submitCourse = (data) => {
    const { id } = routeParams;
    setCourseIsSubmiting(true);

    ApiServices.updateCourseById(token, Number(id), data)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          ApiServices.coursePublishStatus(token, Number(id));
          setCourseIsSubmiting(false);

          enqueueSnackbar(res.data.details, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setCourseIsSubmiting(false);
      });
  };

  const [courseIsSubmiting, setCourseIsSubmiting] = useState(false);

  return (
    <>
      <FusePageCarded
        header={<ProductHeader />}
        content={
          <>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              classes={{ root: "w-full h-64 border-b-1" }}
            >
              <Tab className="h-64" label="Cours" />
              <Tab className="h-64" label="Abonnements" />
              <Tab className="h-64" label="Groupes" />
            </Tabs>
            <div className="p-16 sm:p-24 w-full">
              <div className={tabValue === 0 ? "" : "hidden"}>
                <FormProvider {...methodsCourse}>
                  <form onSubmit={methodsCourse.handleSubmit(submitCourse)}>
                    <CrourseTab />
                    <motion.div
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                      className="flex justify-end"
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className="px-40 py-2"
                        disabled={courseIsSubmiting}
                      >
                        {courseIsSubmiting ? "Updating..." : "Update"}
                      </Button>
                    </motion.div>
                  </form>
                </FormProvider>
              </div>
              {/* Tab 2 */}
              <div className={tabValue === 1 ? "" : "hidden"}>
                <SubscriptionTab />
              </div>
              {/* Tab 3 */}
              <div className={tabValue === 2 ? "" : "hidden"}>
                <GroupsTab tabValueChange={tabValue} />
              </div>
            </div>
          </>
        }
        scroll={isMobile ? "normal" : "content"}
      />
    </>
  );
}

export default SubscriptionsForm;
